/* user_dashboard/UserDashboard.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
}

.user-dashboard-container {
  display: flex;
  flex-direction: column;
  padding: 40px 20px; /* Add horizontal padding */
  align-items: center;
}

.divider-container {
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  max-width: 600px;
}

.divider-container .line {
  border-top: 1px solid #32AFC3;
  flex-grow: 1;
}

.divider-container .divider-text {
  font-weight: bold;
  background-color: #FFF;
  padding: 0 10px;
}

.direct-audit-container {
  max-width: 1200px;
}


.audit-repo-title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 24px;
}

.audit-repo-inputcontainer-dashboard {
  display: flex;
  gap: 0.5rem;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}

.audit-repo-inputfield-dashboard {
  flex: 1;
  min-width: 300px; /* Minimum width before wrapping */
}

.audit-repo-inputfield-dashboard .p-inputtext {
  max-width: 450px;
  min-width: 400px;
  min-height: 40px;
}

.p-inputtext {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button {
  padding: 5px 15px;
  cursor: pointer;
  border: 1px solid #32AFC3;
  background-color: #F9F9F9;
  border-radius: 4px;
  color: #32AFC3;
  padding: 10px 20px;
  cursor: pointer;

}

button:hover {
  background-color: #DFDFDF;
  border: 1px solid #32AFC3;
}


.repository-list-section {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  margin-top: 5rem;
  width: 100%;
  max-width: 800px;
}

.audit-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 150px;
}

/* Style for each audit item */
.audit-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #DFDFDF !important;
}

.audit-item:last-child {
  border-bottom: none;
}

.audit-item-link {
  text-decoration: none; /* Removes underline from the link */
}

.audit-item-link:hover {
  text-decoration: none;
  /* Make mouse a hand when hovering over the link */
  cursor: pointer;
}

.repo-logo {
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  margin-right: 10px;
}

.audit-details {
  flex: 1;
  min-width: 0; /* Allow container to shrink below content size */
  display: flex;
  flex-direction: column;
}

.repo-name {
  color: #323232;
  font-weight: bold;
  font-size: larger;
  margin-bottom: 2px;
}

.repo-name.highlighted {
  color: #DE7539; /* Orange color for highlighted elements */
}

.repo-name.ready {
  color: #2CB392; /* Color for ready elements */
}

.repo-url-container {
  width: 100%;
  min-width: 0; /* Allow container to shrink below content size */
  padding-right: 20px; /* Space for tooltip */
  overflow: hidden; /* Prevent overflow */
}

.repo-url {
  font-size: medium;
  color: #8D8D8D;
  margin-bottom: 5px;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
}

.p-tooltip {
  max-width: 300px;
}

.p-tooltip .p-tooltip-text {
  word-break: break-all;
  font-size: 14px;
  padding: 8px 12px;
}

.audit-info {
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: flex-end; /* Aligns children to the right */
  flex-shrink: 0; /* Prevents shrinking */
}

.audit-info > * {
  margin-bottom: 5px; /* Space between elements */
}

.ai-synthesis {
  color: #323232;
  font-weight: bold;
}

.file-count {
  color: #8D8D8D;
  font-size: medium;
}

.file-count.highlighted {
  color: #DE7539; /* Color for highlighted elements */
  font-weight: 600;
}

.audit-status {
  color: #8D8D8D;
  font-size: medium;
}

.audit-repo-button-dashboard {
  background-color: #2CB392;
  border-color: #2CB392;
  height: 40px;
  color: white;
  min-width: 150px;
  margin: 0 auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;  /* Space between icon and text */
}

.audit-repo-button-dashboard.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.audit-repo-button-dashboard .p-button-label {
  color: white !important;
}

.audit-repo-button-dashboard .p-button-icon {
  color: white !important;
  font-size: 1rem;  /* Adjust icon size if needed */
}

.audit-repo-button-dashboard .pi-spin {
  font-size: 1rem;
}




.p-datascroller-content > .p-datascroller-list > .p-datascroller-item {
  padding-top: 1rem;
}

.p-datascroller-content > .p-datascroller-list > .p-datascroller-item:first-child {
  padding-top: 0;
}

@media (max-width: 1024px) {
  .repository-list-section {
    padding: 10px;
    max-width: 90%;
    margin-left: 50px;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .audit-repo-title {
    font-size: 20px;
  }

  .audit-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .audit-info {
    align-items: flex-start;
    margin-top: 10px;
  }

  .repo-logo {
    margin-bottom: 10px;
  }

  .divider-container {
    margin-left: 50px;
    max-width: 20rem;
  }
  
  .repository-list-section {
    padding: 10px;
    max-width: 90%;
    margin-left: 50px;
  }

  .audit-repo-inputcontainer-dashboard {
    flex-direction: column;
    align-items: center; /* Centers items when stacked */
  }

  .direct-audit-container {
    margin-left: 50px;
    max-width: 85%;
  }

  .audit-repo-inputfield-dashboard .p-inputtext {
    min-width: 400px;
  }

  .audit-repo-button-dashboard {
      width: 100%;
      max-width: 500px; /* Match input field width */
  }

}

@media (max-width: 480px) {
  
  .audit-repo-inputfield-dashboard .p-inputtext {
    min-width: 300px;
  }

  .divider-container {
    margin-left: 50px;
    max-width: 15rem;
  }


  .direct-audit-container {
    margin-left: 50px;
    max-width: 85%;
  }
  
  .audit-repo-title {
    font-size: 18px;
  }

  .p-button {
    width: 100%;
    max-width: none;
  }
}

.search-container {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    position: relative; /* Added for character count positioning */
}

.search-input {
    width: 100%;
    max-width: 400px;
}

.search-input.p-inputtext:focus {
    box-shadow: 0 0 0 1px var(--primary-color);
}

.p-input-icon-left {
    width: 100%;
    max-width: 400px;
}

.p-input-icon-left i {
    color: var(--text-color-secondary);
}

/* Add styles for when approaching character limit */
.search-input.near-limit {
    border-color: var(--yellow-500);
}

.search-input.at-limit {
    border-color: var(--red-500);
}

.no-results-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    color: var(--text-color-secondary);
}

.no-results-message i {
    margin-bottom: 1rem;
}

.no-results-message p {
    font-size: 1.1rem;
    margin: 0;
}

.search-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.search-error-message {
    margin-top: 0.5rem;
    width: 100%;
    max-width: 400px;
}

.search-input.p-invalid {
    border-color: var(--red-500);
}

/* Improve accessibility */
.search-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color);
}

.ai-synthesis {
  font-size: 0.9rem;
}

.file-count {
  font-size: 0.8rem;
}

.audit-status {
  font-size: 0.8rem;
}

.loading-text {
    color: #32AFC3;
    font-size: 0.9rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    gap: 2px;
}

.loading-text .dot-one,
.loading-text .dot-two,
.loading-text .dot-three {
    color: #32AFC3;
    opacity: 0;
    animation: dotFade 1.5s infinite;
}

.loading-text .dot-two {
    animation-delay: 0.5s;
}

.loading-text .dot-three {
    animation-delay: 1s;
}

@keyframes dotFade {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}

.start-audit-text {
    color: var(--primary-color);
    font-size: 0.9rem;
    font-weight: 600;
}

.start-audit-text.highlighted {
    color: #2CB392;
    font-weight: 600;
}

.audit-status {
    font-size: 0.8rem;
    color: #8D8D8D;
    display: block;
    margin-top: 0.25rem;
}

.audit-status strong {
    color: #323232;
    font-weight: bold;
    font-size: 0.8rem;
}

/* Ensure the audit scores grid is properly styled */
.audit-scores-grid {
    display: grid;
    gap: 0.5rem;
    margin-top: 1rem;
    width: 100%;
}

.input-progress-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
}

.progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(50, 175, 195, 0.6); /* #32AFC3 with 0.3 opacity */
    transition: width 0.5s ease-in-out;
    pointer-events: none; /* Allows clicking through to the input */
    border-radius: 6px;
    z-index: 5;
}

.dashboard-status-message {
    color: #8D8D8D;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    text-align: left;
    padding-left: 0.5rem;
}

/* Remove old progress bar styles */
.audit-status-container,
.progress-bar-container,
.progress-bar {
    display: none;
}

/* Ensure input maintains its style when disabled */
.p-inputtext:disabled {
    opacity: 1;
    background-color: #ffffff;
    color: #323232;
}

.progress-details {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.progress-details > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 1.4;
}

.progress-details > div:first-child {
    font-weight: 500;
    color: var(--text-color);
}

.status-message > div:first-child {
    font-weight: 500;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
}

/* Add animation for progress updates */
.progress-details > div {
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
