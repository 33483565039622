* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
}

.p-sidebar-header .p-sidebar-close {
    display: none;
}

.sidebar-container {
    position: relative;
}

.responsive-sidebar {
    width: 100% !important;
    max-width: 350px;
    transition: max-width 0.3s ease-in-out;
}

.responsive-sidebar.collapsed {
    max-width: 40px;
    overflow: hidden;
}

.toggle-button {
    position: absolute;
    top: 10px;
    z-index: 1000;
    width: 30px !important;
    height: 30px !important;
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    transition: background-color 0.2s, left 0.3s ease-in-out;
}

.toggle-button:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.toggle-button:focus {
    box-shadow: none !important;
}

.toggle-button .p-button-icon {
    font-size: 1rem;
}

.responsive-sidebar.collapsed .toggle-button {
    left: 5px;
}

.confirm-button {
    margin-top: 50px;
    width: 100%;
}

.audit-summary {
    margin-top: 45px;
    padding: 0 10px;
}

.files-to-audit, .lines-of-code, .repositories-to-audit {
    margin-bottom: 10px;
    margin-top: 25px;
}

.audit-order {
    margin-top: 25px;
    padding: 0 10px;
}

.start-audit-button {
    margin-top: 15px;
    height: 40px;
    width: 100%; 
    background: #2CB392;
    min-width: 100%;
}

.start-audit-button .p-button-label {
    color: #fff;         
}

.start-audit-button .p-button-icon {
    color: white;
}

.start-audit-button.disabled .p-button-icon {
    color: #8D8D8D;
}

.start-audit-button:hover {
    background: #25997E;
    border: none;
}

.start-audit-button.disabled {
    background-color: #D9D9D9;
    border-color: #2CB392;
}

.start-audit-button.disabled .p-button-label {
    color: #8D8D8D;
}

.audit-info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.audit-info-row-small {
    font-size: 12px;
    font-style: italic;
    color: #8D8D8D;
}

.audit-value {
    font-weight: bold;
}

.audit-divider {
    border: 0.5px solid #ccc;
    margin: 10px 0;
}

.audit-warning {
    color: #E83636;
    margin-top: 10px;
}

.confirmation-message {
    margin: 25px 0;
}

.confirmation-message h4 {
    margin-bottom: 5px;
}

.set-group-audit-ready {
    width: 100%;
    background-color: #2CB392;
    border: none;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
    min-width: 100%;
}

.set-group-audit-ready:hover {
    background-color: #25997E;
}

.set-group-audit-ready.disabled {
    background-color: #D9D9D9;
    border-color: #2CB392;
    color: #8D8D8D;
}

.set-group-audit-ready .p-button-icon {
    color: white;
}

.set-group-audit-ready.disabled .p-button-icon {
    color: #8D8D8D;
}

@media (max-width: 768px) {
    .responsive-sidebar {
        max-width: 100%;
    }

    .audit-summary, .audit-order {
        padding: 0 20px;
    }
}

@media (max-width: 1075px) {
    
    .toggle-button {
        left: 5px;
    }
}