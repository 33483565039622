.files-page-layout {
    position: relative;
    display: flex;
    flex-direction: column; 
    padding-left: 45px;
    background-color: #F9F9F9;
    justify-content: center;
    height: 100%;
  }

.file_comment_card {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

/* Add a class for the adjust table content container */
.adjust-table-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: #F9F9F9;
  justify-content: space-between; /* To span the entire width */
  align-items: center; /* Vertically center align */
  padding-left: 35px;
}

.adjust-table-title {
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  color: #32AFC3;
}

.adjust-table-text {
  font-size: 16px;
  padding: 0 0 20px 50px;
  color: #8D8D8D;
}

.adjust-table-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* creates two columns */
  align-items: center;
  gap: 0px;
  margin: 0 10px;
}

.p-multiselect {
  width: 300px;
  margin-right: 10px;
  padding: 5px;
}

.p-multiselect-item {
  padding-left: 10px;
}

.files-page-layout a {
  text-decoration: underline;
  cursor: pointer;
}

.p-checkbox {
  margin-right: 5px;
}

.attribute-dropdown {
  flex: 1;
  min-width: 250px; /* Adjust as needed */
  margin: 10px;
  width: 100%;
  margin-right: 15px;
}

.p-float-label {
  width: 100%;
  display: block; /* To ensure the label positions correctly */
}

.p-multiselect-label {
  margin-left: 10px;
  color: #323232;
}

.custom-multiselect-label {
  font-size: 18px;
  color: #9B9B9B;
  transform: translateY(-12px); 
  display: block; /* If not already set, ensures transform applies correctly */
}

.p-float-label.floating .custom-multiselect-label {
  font-size: 12px;
}

/* Remove border and set transparent background */
.p-treetable .p-treetable-toggler {
  border: none;
  background-color: transparent;
  border-radius: 50%; /* Makes the button round */
}

/* Custom icon for collapsed state (using pi-folder) */
.p-treetable .p-treetable-toggler::before {
  content: "\e963"; /* Unicode for pi-folder */
  font-family: "PrimeIcons";
  font-size: 18px;
  color: #323232;
}

/* Custom icon for expanded state (using pi-folder-open) */
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler.p-treetable-toggler-open::before {
  content: "\e964"; /* Unicode for pi-folder-open */
}

/* Remove hover effect and set cursor */
.p-treetable .p-treetable-toggler:hover {
  background-color: transparent; /* Removes any hover background color */
  cursor: pointer; /* Sets the cursor to pointer */
}

.file-loader {width: 40px;
  height: 40px;
  --c:no-repeat linear-gradient(#32AFC3 0 0);
  background: var(--c),var(--c),var(--c),var(--c);
  background-size: 21px 21px;
  animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
  }
  
  @keyframes l5 {
  0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
  33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
  66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
  100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
  }
  
  .file-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding-top: 50vh;
    transform: translateY(-50%);
  }

.files-container {
  margin-left: 35px;
  margin-bottom: 40px;
  border-radius: 8px;
  border-color: #DFDFDF;
}

.files-header {
  margin-bottom: 20px;
  background-color: #f8f9fa;
  border-radius: 6px;
}

.files-content {
  background-color: var(--surface-card);
  padding: 1.5rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.files-statistics-section {
  margin-bottom: 20px;
}

.attribute-selection-wrapper {
  border-radius: 6px;
  margin-bottom: 1.5rem;
}

.attribute-selection-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px 0 20px 0;
  transition: background-color 0.2s ease;
}

.attribute-selection-header:hover {
  background-color: #f0f0f0;
}

.attribute-selection-header i {
  font-size: 18px;
  margin-right: 10px;
  color: #32AFC3;
}

.attribute-selection-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.attribute-selection-content.expanded {
  max-height: 1000px; /* Adjust this value based on your content */
  transition: max-height 0.5s ease-in;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1050px) {
  .adjust-table-content {
      grid-template-columns: 2fr;
  }

  .attribute-dropdown {
      width: 100%;
      margin: 10px 0;
  }

  .p-float-label {
    width: 100%; /* Full width on smaller screens */
  }
}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .adjust-table-content {
      grid-template-columns: 1fr;
  }
}

.files-statistics-title {
    font-size: 18px;
    font-weight: bold;
    color: #32AFC3;
    margin: 0;
    padding: 0;
}

.files-statistics-description {
    font-size: 16px;
    color: #8D8D8D;
    margin: 10px 0 20px 0;
    padding: 0;
}