.checkout-content {
    display: flex;
    flex-direction: column;
}

.order-summary {
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-details {
    display: flex;
    flex-direction: column;
}

.order-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-row.highlighted {
    font-weight: 500;
    color: #32AFC3;
}

.order-row.breakdown-row {
    font-size: 0.9rem;
    color: #666;
    padding-left: 1rem;
}

.order-row.total {
    border-top: 1px solid #f0f0f0;
    margin-top: 10px;
    padding-top: 10px;
    font-weight: bold;
    font-size: 1.1em;
}

.highlight {
    font-weight: bold;
    color: #32AFC3;
}

.payment-integration {
    display: flex;
    flex-direction: column;
}

.payment-integration h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #495057;
}

.payment-note {
    font-size: 0.875em;
    color: #6c757d;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
}

.payment-note i {
    margin-right: 5px;
}

.payment-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 30px 0;
}

.payment-success {
    text-align: center;
    padding: 20px 0;
}

.payment-error-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.audit-cards-checkout {
    display: flex; 
    flex-wrap: nowrap;
    gap: 15px; 
    width: 100%;
    overflow: visible; 
    margin-top: 15px
}

/* Responsive adjustments */
@media (min-width: 768px) {
    .audit-cards {
        flex-direction: row;
    }

    .audit-card {
        min-height: 120px;
    }
}

@media (max-width: 767px) {
    .order-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }
    
    .order-row.total {
        flex-direction: row;
    }
}

/* Payment Method Selector */
.payment-method-selector {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.payment-method-selector h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.payment-methods {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 15px;
  margin-bottom: 30px;
}

.payment-method-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.payment-method-option:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-color: #2196F3;
}

.payment-method-option i {
  font-size: 24px;
  margin-bottom: 10px;
  color: #2196F3;
}

.payment-method-option span {
  font-size: 14px;
  text-align: center;
  color: #333;
}

.payment-selector-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Payment Form Styling */
.stripe-payment-form {
  position: relative;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.payment-form-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.payment-form-header h3 {
  margin: 0 auto;
  color: #333;
}

.back-button {
  font-size: 0.9rem;
}

.stripe-container {
    width: 100%;
    min-height: 200px;
    padding: 0;
    background-color: #ffffff;
}

.stripe-element-container {
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #ffffff;
    margin-bottom: 20px;
    min-height: 150px;
    box-shadow: none;
}

.payment-processing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 4px;
}

.payment-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.loading-spinner {
  margin-bottom: 10px;
}

.stripe-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

/* Audit Card Styles */
.audit-cards {
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    width: 100%;
}

.audit-card-wrapper {
    opacity: 1;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex: 1;
}

.audit-card {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
    border: 1px solid #e0e0e0;
}

.audit-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.audit-card-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
}

.audit-logo {
    height: 28px !important;
    width: auto;
    object-fit: contain !important;
    align-self: flex-start;
    margin-bottom: 4px;
}

.audit-repo-link {
    color: #666;
    text-decoration: none;
    font-size: 0.8rem !important;
    transition: color 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
}

.audit-repo-link:hover {
    color: #32AFC3;
}

.audit-stats {
    color: #666;
    font-size: 0.8rem !important;
    margin-top: 4px;
}

/* Sample audits section */
.sample-audits-section {
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
}

@media (max-width: 600px) {
    .audit-cards {
        flex-direction: column !important;
    }
    
    .audit-card-wrapper {
        margin-bottom: 10px;
    }
}

/* Payment success/cancelled pages */
.payment-success-container,
.payment-cancelled-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 100px);
    padding: 20px;
    background-color: #f5f7fa;
}

.payment-success-card,
.payment-cancelled-card {
    width: 100%;
    max-width: 600px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.payment-success,
.payment-cancelled,
.payment-loading,
.payment-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px 20px;
}

.payment-success h2,
.payment-cancelled h2,
.payment-loading h2,
.payment-error h2 {
    margin: 20px 0 10px;
    color: #333;
    font-size: 1.5rem;
}

.payment-success p,
.payment-cancelled p,
.payment-loading p,
.payment-error p {
    margin-bottom: 30px;
    color: #666;
    font-size: 1rem;
    max-width: 450px;
}

.payment-details {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    width: 100%;
    text-align: left;
}

.payment-details h3 {
    margin-top: 0;
    color: #333;
    font-size: 1.2rem;
    margin-bottom: 15px;
}

.payment-detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 0.9rem;
}

.payment-detail-label {
    font-weight: 600;
    color: #666;
}

.payment-detail-value {
    color: #000;
}

.payment-confirmation,
.payment-processing {
    font-style: italic;
    margin-top: 15px;
    background-color: #f0f9ff;
    padding: 10px;
    border-radius: 5px;
    border-left: 4px solid #32AFC3;
    text-align: left;
}

.payment-processing {
    background-color: #fffbeb;
    border-left-color: #f59e0b;
}

.payment-options {
    text-align: left;
    margin: 10px 0 30px;
    width: 100%;
}

.payment-options h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #333;
}

.payment-options ul {
    list-style-type: none;
    padding-left: 0;
}

.payment-options li {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
}

.payment-options li:before {
    content: "•";
    color: #32AFC3;
    font-weight: bold;
    margin-right: 10px;
}

.payment-actions {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

.payment-actions .p-button {
    min-width: 150px;
}

@media (max-width: 600px) {
    .payment-actions {
        flex-direction: column;
        width: 100%;
    }
    
    .payment-actions .p-button {
        width: 100%;
    }
}

/* Pricing table styles */
.pricing-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.pricing-table th, .pricing-table td {
    padding: 10px 15px;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;
}

.pricing-table th {
    background-color: #f8f9fa;
    font-weight: 600;
}

.pricing-table tr:last-child td {
    font-weight: bold;
    border-top: 2px solid #e0e0e0;
    border-bottom: none;
}

/* Payment container */
.cs-payment-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin-top: 20px;
}

/* Payment success styles */
.cs-payment-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px 20px;
    background-color: #f0f8f0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    margin: 20px 0;
    overflow: hidden;
}

.cs-payment-success-details {
    margin-top: 20px;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 6px;
    width: 100%;
    max-width: 450px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cs-payment-success-details p {
    margin-bottom: 15px;
    color: #333;
}

/* Payment error styles */
.cs-payment-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px 20px;
    background-color: #fff0f0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    margin: 20px 0;
}

/* Payment loading styles */
.cs-payment-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    text-align: center;
}

/* Payment redirecting styles */
.cs-payment-redirecting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    text-align: center;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Payment options styles */
.cs-payment-options {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.cs-payment-security-note {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: #666;
    background-color: #f0f8ff;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
}


/* Checkout stepper container */
.cs-checkout-stepper-container {
    width: 100%;
}

/* Sample audits section */
.sample-audits-section {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #e0e0e0;
}

/* Payment actions */
.cs-payment-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .cs-payment-actions {
        flex-direction: column;
        gap: 10px;
    }
    
    .cs-payment-actions .p-button {
        width: 100%;
    }
} 