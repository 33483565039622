.logs-container {
    background: var(--surface-card);
    border-radius: 10px;
    margin-left: 50px;
    padding: 1rem;
}

.logs-table-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-left: 50px;
}

.filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.filter-container > * {
    margin-right: 0.5rem;
}

.filter-container .p-dropdown,
.filter-container .p-calendar,
.filter-container .p-inputtext {
    min-width: 200px;
}

.message-cell {
    white-space: pre-wrap;
    word-break: break-word;
    max-height: 100px;
    overflow-y: auto;
}

/* Log Level Styles */
.log-level-info {
    background-color: #2196F3;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.log-level-warning {
    background-color: #FF9800;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.log-level-error {
    background-color: #F44336;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.log-level-debug {
    background-color: #9E9E9E;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

/* Step Status Styles */
.step-status-pending {
    background-color: #9E9E9E;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.step-status-running, 
.step-status-in_progress,
.step-status-processing {
    background-color: #2196F3;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.step-status-completed {
    background-color: #e3fcef;
    color: #0d9488;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
}

.step-status-failed {
    background-color: #fee2e2;
    color: #dc2626;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
}

.step-status-retrying {
    background-color: #FF9800;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.step-status-incomplete {
    background-color: #FF9800;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

/* Log Details Dialog */
.log-details {
    padding: 1rem;
}

.log-details-dialog .p-dialog-content {
    padding: 2rem;
}

.detail-row {
    margin-bottom: 1.5rem;
}

.detail-label {
    font-weight: 600;
    margin-right: 1rem;
    min-width: 120px;
    display: inline-block;
}

.message-content,
.error-content,
.metadata-content {
    background: var(--surface-100);
    padding: 1rem;
    border-radius: 4px;
    margin-top: 0.5rem;
    white-space: pre-wrap;
    word-break: break-word;
    max-height: 300px;
    overflow-y: auto;
}

.error-content {
    background: #fff5f5;
}

.metadata-content {
    background: #f0f7ff;
}

/* Responsive Design */
@media screen and (max-width: 960px) {
    .filter-container {
        flex-direction: column;
        align-items: stretch;
    }

    .filter-container > * {
        width: 100%;
        margin-right: 0;
    }

    .message-cell {
        max-width: 200px;
    }
}

/* DataTable Custom Styles */
.p-datatable-logs .p-datatable-thead > tr > th {
    background: var(--surface-ground);
    color: var(--text-color);
    padding: 1rem;
}

.p-datatable-logs .p-datatable-tbody > tr {
    background: var(--surface-card);
    cursor: pointer;
    transition: background-color 0.2s;
}

.p-datatable-logs .p-datatable-tbody > tr:hover {
    background-color: var(--surface-100) !important;
}

/* Calendar and Dropdown Custom Styles */
.p-calendar,
.p-dropdown {
    min-width: 200px;
}

.p-inputtext {
    min-width: 200px;
}

/* Improve module column appearance */
.p-datatable .p-datatable-tbody > tr > td[data-field="module_name"] {
    text-transform: capitalize;
    font-weight: 500;
}

/* Make the status colors more subtle */
.step-status-in_progress {
    background-color: #e0f2fe;
    color: #0284c7;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
}

/* Make Running match In Progress style */
.step-status-running {
    background-color: #e0f2fe;
    color: #0284c7;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
}

/* Structured Message Styles */
.structured-message {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.message-main {
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 1.5;
    font-weight: 500;
    padding: 1rem;
    background: var(--surface-50);
    border-radius: 6px;
}

.message-details {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.extras-section {
    background: var(--surface-50);
    border-radius: 6px;
    padding: 1.5rem;
}

.extras-section h3 {
    margin: 0 0 1rem 0;
    color: var(--text-900);
    font-size: 1rem;
    font-weight: 600;
}

.extras-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}

.extras-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.75rem;
    background: var(--surface-0);
    border-radius: 4px;
}

.extras-label {
    color: var(--text-600);
    font-size: 0.875rem;
    text-transform: capitalize;
}

.extras-value {
    color: var(--text-900);
    font-weight: 500;
    word-break: break-word;
}

.message-error {
    background: #fff5f5;
    border-radius: 6px;
    padding: 1.5rem;
}

.message-error h3 {
    margin: 0 0 1rem 0;
    color: #dc2626;
    font-size: 1rem;
    font-weight: 600;
}

.error-type {
    color: #dc2626;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.error-message {
    color: #7f1d1d;
    margin-bottom: 1rem;
    line-height: 1.5;
}

.stack-trace {
    background: #f8fafc;
    border-radius: 4px;
    padding: 1rem;
    margin-top: 1rem;
}

.stack-trace-label {
    color: #475569;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.stack-trace pre {
    margin: 0;
    white-space: pre-wrap;
    word-break: break-word;
    color: #64748b;
    font-size: 0.875rem;
    line-height: 1.5;
    max-height: 300px;
    overflow-y: auto;
}

/* Message cell in table */
.message-cell {
    max-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--text-color);
}

/* Update existing message-content class */
.message-content {
    background: var(--surface-100);
    padding: 1.5rem;
    border-radius: 6px;
    margin-top: 0.5rem;
    overflow-y: auto;
    max-height: 500px;
}

.message-content pre {
    margin: 0;
    white-space: pre-wrap;
    word-break: break-word;
}

/* Button container styles */
.button-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
