.pricing-page {
  min-height: 100vh;
  background-image: linear-gradient(100deg, #ffffff 0%, rgba(149, 219, 231, 0.2) 30%, rgba(243, 143, 252, 0.2) 100%);
}

/* Header Section */
.pricing-header {
  padding: 80px 0 40px;
}

.pricing-header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  text-align: left;
}

.pricing-header h1 {
  font-size: 3rem;
  color: #1C2B40;
  margin: 0 0 24px 0;
  font-weight: 600;
  line-height: 1.2;
}

.pricing-header p {
  font-size: 1.2rem;
  color: #666;
  margin: 0;
  line-height: 1.6;
  max-width: 800px;
}

/* Pricing Cards Section */
.pricing-cards-section {
  padding: 20px 0 60px;
}

.pricing-cards-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  gap: 32px;
}

.per-audit-container {
  flex: 2;
}

.enterprise-container {
  flex: 1;
}

/* Per Audit Card */
.per-audit-card {
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  height: 100%;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.per-audit-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(90deg, #32AFC3 0%, #7B96E6 100%);
}

.per-audit-card-content {
  padding: 32px;
  height: 100%;
}

.per-audit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.per-audit-header h2 {
  font-size: 2rem;
  margin: 0;
  font-weight: 600;
}

.per-audit-badge {
  background: linear-gradient(90deg, #32AFC3 0%, #7B96E6 100%);
  color: white;
  padding: 6px 12px;
  border-radius: 24px;
  font-size: 0.9rem;
  font-weight: 500;
}

.per-audit-card p {
  font-size: 1.1rem;
  color: #666;
  margin: 0 0 24px 0;
  line-height: 1.6;
}

/* Tier list inside Per Audit card */
.tier-list {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 24px;
}

.tier-header {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  padding: 0 0 12px 0;
  border-bottom: 2px solid #eee;
  font-weight: 600;
  color: #444;
}

.tier-row {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  padding: 16px 0;
  border-bottom: 1px solid #eee;
}

.tier-row:last-child {
  border-bottom: none;
}

.tier-name {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.tier-range {
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tier-price {
  font-weight: 600;
  color: #1C2B40;
}

.tier-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.per-audit-features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.feature-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #444;
}

/* Enterprise Card */
.enterprise-card {
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  height: 100%;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.enterprise-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: rgb(50, 50, 50);
}

.enterprise-card-content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.enterprise-card h2 {
  font-size: 2rem;
  margin: 0 0 16px 0;
  font-weight: 600;
}

.enterprise-card p {
  font-size: 1.1rem;
  color: #666;
  margin: 0 0 24px 0;
  line-height: 1.6;
}

.enterprise-features {
  list-style: none;
  padding: 0;
  margin: 0 0 32px 0;
  flex-grow: 1;
}

.enterprise-features li {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #444;
}

.enterprise-button {
  background-color: #323232 !important;
  color: white !important;
  border: 2px solid #323232 !important;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: auto;
}

.enterprise-button:hover {
  background-color: #4a4a4a !important;
  border: 2px solid #4a4a4a !important;
}

/* Pricing Calculator Section */
.pricing-calculator-container {
  background: #f8f9fa;
  padding: 60px 0;
}

.pricing-calculator-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.pricing-calculator-section h2 {
  font-size: 2.5rem;
  color: #1C2B40;
  margin: 0 0 16px 0;
  text-align: center;
}

.calculator-description {
  text-align: center;
  font-size: 1.1rem;
  color: #666;
  margin: 0 auto 40px;
  max-width: 800px;
}

.pricing-calculator-card {
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.calculator-content {
  padding: 32px;
}

.calculator-input {
  margin-bottom: 32px;
}

.calculator-input label {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #1C2B40;
}

.lines-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Custom styling for PrimeReact Slider */
.lines-input-wrapper .p-slider {
  background: #e0e0e0;
  border-radius: 10px;
  height: 6px;
}

.lines-input-wrapper .p-slider .p-slider-range {
  background: #32AFC3;
  border-radius: 10px;
}

.lines-input-wrapper .p-slider .p-slider-handle {
  background: #32AFC3;
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-top: -10px;
  margin-left: -10px;
  transition: background-color 0.2s;
  cursor: grab;
}

.lines-input-wrapper .p-slider .p-slider-handle:hover {
  background: #2a95a7;
}

.lines-input-wrapper .p-slider .p-slider-handle:active {
  cursor: grabbing;
}

/* Step markers for the slider */
.lines-input-wrapper .p-slider .p-slider-step {
  height: 6px;
}

.lines-input-wrapper .p-slider .p-slider-step .p-slider-step-marker {
  background: #ffffff;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  top: 0;
  margin-left: -3px;
  z-index: 1;
}

.calculator-results-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 32px;
  align-items: start;
}

.calculator-results {
  padding: 24px;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #eee;
}

.result-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #e0e0e0;
}

.result-item:last-child {
  border-bottom: none;
}

.result-item.no-border {
  border-bottom: none;
}

.result-item.total {
  font-weight: 700;
  font-size: 1.2rem;
  padding-top: 16px;
  margin-top: 8px;
  border-top: 2px solid #ddd;
  border-bottom: none;
}

.result-label {
  color: #666;
}

.result-value {
  font-weight: 600;
  color: #333;
}

.tier-badge {
  background: linear-gradient(90deg, #7B96E6 0%, #9F5FE9 100%);
  color: white;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.price-breakdown {
  width: 100%;
}

.price-breakdown h3 {
  margin-bottom: 24px;
  font-size: 1.4rem;
  color: #1C2B40;
}

.breakdown-chart {
  width: 100%;
  height: 300px;
  margin-bottom: 32px;
  overflow: hidden;
}

.breakdown-chart svg {
  width: 100%;
  height: 100%;
}

.breakdown-chart .bar {
  transition: opacity 0.3s;
}

.breakdown-chart .bar:hover {
  opacity: 0.8;
}

.breakdown-chart .label {
  fill: #444;
  font-weight: 600;
  font-size: 12px;
}

.breakdown-table {
  margin-top: 40px;
}

.breakdown-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 16px 0;
  border-bottom: 1px solid #eee;
}

.breakdown-tier {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.breakdown-amount {
  font-weight: 600;
  text-align: right;
}

/* FAQ Section */
.pricing-faq {
  background: white;
  padding: 60px 0;
}

.pricing-faq-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.pricing-faq h2 {
  font-size: 2.5rem;
  color: #1C2B40;
  margin: 0 0 40px 0;
  text-align: center;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

.faq-item {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.faq-item h3 {
  font-size: 1.2rem;
  margin: 0 0 16px 0;
  color: #1C2B40;
}

.faq-item p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin: 0;
}

/* Media Queries */
@media (max-width: 1024px) {
  .pricing-header h1 {
    font-size: 2.5rem;
  }
  
  .faq-grid {
    grid-template-columns: 1fr;
  }
  
  .pricing-cards-container {
    flex-direction: column;
  }
  
  .per-audit-container,
  .enterprise-container {
    flex: auto;
  }
  
  .calculator-results-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .pricing-header {
    padding: 60px 0 30px;
  }

  .pricing-header h1 {
    font-size: 2rem;
  }

  .pricing-header p {
    font-size: 1.1rem;
  }
  
  .per-audit-features {
    grid-template-columns: 1fr;
  }
  
  .breakdown-row {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding: 16px 0;
  }
  
  .breakdown-tier, .breakdown-lines {
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .tier-range {
    font-size: 0.9rem;
  }
  
  .tier-row {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
  }
  
  .tier-price {
    font-size: 0.9rem;
  }

  .price-breakdown {
    max-width: 350px;
  }

  .breakdown-chart {
    max-width: 100%;
    margin: 0 auto 24px;
  }
  
  .breakdown-chart .label {
    font-size: 10px;
  }
  
  .breakdown-chart .tick text {
    font-size: 9px;
  }
}
