.development-page-layout {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 45px;
    background-color: #F9F9F9;
}

.development-content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 0; /* Required for Firefox */
}

.development-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: auto;
}

.development-section-title {
    font-size: 24px;
    font-weight: bold;
    color: #32AFC3;
    margin: 0;
    padding: 0;
}

.development-section-subtitle {
    font-size: 16px;
    color: #8D8D8D;
    margin: 10px 0 20px 0;
    padding: 0;
}

.development-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.development-card h3 {
    color: #323232;
    font-size: 18px;
    margin: 0 0 15px 0;
}

.development-stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.development-stat-item {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #F9F9F9;
    border-radius: 6px;
    transition: transform 0.2s ease;

}

.development-stat-item:hover {
    transform: translateY(-2px);
}

.development-stat-label-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.development-stat-label {
    font-size: 14px;
    color: #8D8D8D;
}

.tooltip-icon {
    font-size: 14px;
    color: #8D8D8D;
    cursor: help;
    transition: color 0.2s ease;
}

.tooltip-icon:hover {
    color: #32AFC3;
}

.development-stat-value {
    font-size: 24px;
    font-weight: bold;
    color: #32AFC3;
    display: flex;
    align-items: center;
    min-height: 36px; /* Ensure consistent height when showing spinner */
}

.development-stat-value .p-progress-spinner {
    margin: 0 auto;
}

.development-stat-value.additions {
    color: #2CB392;
}

.development-stat-value.additions .p-progress-spinner-circle {
    stroke: #2CB392;
}

.development-stat-value.deletions {
    color: #E83636;
}

.development-stat-value.deletions .p-progress-spinner-circle {
    stroke: #E83636;
}

/* Loader styles */
.development-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.development-loader {
    width: 40px;
    height: 40px;
    --c: no-repeat linear-gradient(#32AFC3 0 0);
    background: var(--c), var(--c), var(--c), var(--c);
    background-size: 21px 21px;
    animation: development-loader 1.5s infinite cubic-bezier(0.3,1,0,1);
}

@keyframes development-loader {
    0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
    33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
    66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
    100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .development-page-layout {
        padding-left: 20px;
        padding-right: 20px;
    }

    .development-stats-grid {
        grid-template-columns: 1fr;
    }

    .development-stat-item {
        padding: 10px;
    }

}

.development-activity-section {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #e5e7eb;
}

.development-activity-title {
    font-size: 18px;
    font-weight: 600;
    color: #323232;
    margin: 0 0 10px 0;
}

.development-activity-subtitle {
    font-size: 14px;
    color: #6b7280;
    margin: 0 0 20px 0;
}

.development-summary-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    padding: 25px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.development-summary-header {
    margin-bottom: 20px;
}

.development-summary-divider {
    height: 1px;
    background-color: #e5e7eb;
    margin: 20px 0;
    width: 100%;
}

.development-summary-content {
    display: flex;
    flex-direction: column;
}

.development-summary-analysis {
    padding: 20px;
    background-color: #f8fafc;
    border-radius: 6px;
    border-left: 4px solid #32AFC3;
}

.development-summary-analysis h3 {
    color: #323232;
    font-size: 18px;
    margin: 0 0 15px 0;
    font-weight: 600;
}

.development-summary-text {
    color: #4a5568;
    font-size: 15px;
    line-height: 1.6;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.development-summary-text p {
    margin: 0;
    padding: 0;
}

/* Add spacing before sections that end with colon */
.development-summary-text p[data-is-section="true"] {
    margin-top: 1.5em;
    font-weight: 600;
    color: #2d3748;
}

/* Style bullet points */
.development-summary-text p[data-is-bullet="true"] {
    padding-left: 1.5em;
    position: relative;
}

.development-summary-text p[data-is-bullet="true"]::before {
    content: "";
    position: absolute;
    left: 0.5em;
    top: 0.7em;
    width: 4px;
    height: 4px;
    background-color: #32AFC3;
    border-radius: 50%;
}

/* Style sub-bullet points (starting with *) */
.development-summary-text p[data-is-sub-bullet="true"] {
    padding-left: 2em;
    position: relative;
}

.development-summary-text p[data-is-sub-bullet="true"]::before {
    content: "";
    position: absolute;
    left: 1em;
    top: 0.7em;
    width: 4px;
    height: 4px;
    border: 1px solid #32AFC3;
    border-radius: 50%;
    background-color: white;
}

@media (max-width: 768px) {
    .development-summary-card {
        padding: 15px;
    }
    .development-content {
        margin-left: 30px;
        padding-right: 0px;
    } 
}

.code-changes-container {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
}

.code-changes-metrics {
    flex: 1;
    min-width: 300px;
}

.code-changes-visualization {
    flex: 2;
    min-width: 500px;
    display: flex;
    flex-direction: column;
}

.file-details-container {
    margin-top: 1.5rem;
    animation: slideDown 0.3s ease-out;
}

.file-details {
    padding: 1.5rem;
    background: #F9FAFB;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.file-details h4 {
    margin: 0 0 1rem 0;
    color: #111827;
    font-size: 1.1rem;
}

.file-stats {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.stat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stat-label {
    color: #6B7280;
    font-size: 0.9rem;
}

.stat-bar {
    height: 8px;
    background: #F3F4F6;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
}

.stat-bar .additions {
    background: #2CB392;
    height: 100%;
}

.stat-bar .deletions {
    background: #E83636;
    height: 100%;
}

.file-name-link {
    color: #32AFC3;
    cursor: pointer;
    transition: color 0.2s ease;
}

.file-name-link:hover {
    color: #2890A3;
    text-decoration: underline;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.visualization-description {
    margin-bottom: 1.5rem;
}

.visualization-description h4 {
    font-size: 1.1rem;
    color: #111827;
    margin: 0 0 0.5rem 0;
}

.visualization-description p {
    font-size: 0.95rem;
    color: #6B7280;
    line-height: 1.5;
    margin: 0;
}

/* In HotspotsBubbleChart.css */
.hotspots-bubble-chart {
    position: relative;
    width: 100%;
    height: 500px;
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    overflow: hidden;
    margin-top: 1rem;
}

.hotspots-bubble-chart > div {
    width: 100%;
    height: 100%;
    position: relative;
}

.hotspots-bubble-chart svg {
    width: 100%;
    height: 100%;
    display: block; /* Ensure proper rendering */
}

.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
}

/* Mobile responsive styles */
@media (max-width: 1024px) {
    .code-changes-container {
        flex-direction: column;
        gap: 1.5rem;
        padding: 0.5rem;
    }

    .code-changes-metrics,
    .code-changes-visualization {
        flex: none;
        width: 100%;
        min-width: unset;
    }

    .development-stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 640px) {
    .development-stats-grid {
        grid-template-columns: 1fr;
    }

    .code-changes-container {
        padding: 0;
    }

    .development-card {
        padding: 15px;
    }

    .file-details {
        padding: 1rem;
    }
}

@media (max-width: 1024px) {
    .hotspots-bubble-chart {
        height: 400px;
        padding: 1rem;
    }
}

@media (max-width: 640px) {
    .hotspots-bubble-chart {
        height: 350px;
        padding: 0.75rem;
    }
}

.visualization-description {
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
}

@media (max-width: 640px) {
    .visualization-description {
        padding: 0;
    }

    .visualization-description h4 {
        font-size: 1rem;
    }

    .visualization-description p {
        font-size: 0.875rem;
    }
}



