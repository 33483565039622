/* Base sidebar styles */
:root {
  --sidebar-width-collapsed: 3.125rem;  /* 50px */
  --sidebar-width-expanded: 12.5rem;    /* 200px */
  --button-size: 2.5rem;                /* 40px */
  --sidebar-transition: 0.2s;
}

/* Prevent horizontal scrollbar during sidebar expansion */
body {
  overflow-x: hidden;
}

.global-sidebar {
  width: var(--sidebar-width-collapsed);
  transition: width var(--sidebar-transition);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  background: #fff;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.global-sidebar.expanded {
  width: var(--sidebar-width-expanded);
}

/* Header section */
.sidebar-header {
  padding-top: 1rem;
  height: 4rem;
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

/* Main content with flexbox layout */
.sidebar-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 4rem);
}

.sidebar-top-section, 
.sidebar-bottom-section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  min-width: 0; /* Ensure content doesn't force container to grow */
}

.sidebar-top-section {
  padding-top: 1rem;
}

.sidebar-bottom-section {
  margin-top: auto;
  padding-bottom: 1.5rem;
}

/* Logo styles */
.logo-container-sidebar {
  width: var(--sidebar-width-collapsed);
  height: var(--button-size);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-logo {
  width: var(--button-size);
  height: var(--button-size);
  object-fit: contain;
}

/* Common button styles */
.sidebar-button {
  background: none;
  border: none;
  color: #343a40;
  font-size: 1.5em;
  height: var(--button-size);
  width: var(--button-size);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all var(--sidebar-transition);
  position: relative;
  border: 1px solid #343a40;
  border-radius: 50%;
  flex-shrink: 0;
}

.global-sidebar.expanded .sidebar-button {
  width: calc(100% - 1.25rem);
  height: var(--button-size);
  margin: 0 auto;
  justify-content: flex-start;
  padding-left: 0.625rem;
  border-radius: 0.25rem;
  border: none;
}

.sidebar-button .button-label {
  display: none;
  margin-left: 0.625rem;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.global-sidebar.expanded .sidebar-button .button-label {
  display: inline;
}

/* Admin buttons styling */
.admin-button i,
.audit-logs-button i {
  color: #C049DE;
}

/* Lock button */
.lock-button {
  margin-left: auto;
  margin-right: 0.625rem;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  color: #343a40;
  transition: background-color var(--sidebar-transition);
  flex-shrink: 0;
}

/* Hover effects */
.sidebar-button:hover {
  background-color: #C8E6C9;
}

/* Dialog styles */
.manage-access-modal {
  min-width: 300px;
}

.exports-modal {
  max-width: 900px;
}

.manage-access-modal .p-dialog-header {
  background-color: #32AFC3;
}

.manage-access-modal .p-dialog-title {
  color: white;
  padding-left: 1.25rem;
}

/* Mobile Responsive Breakpoints */
@media screen and (max-width: 768px) {
  .sidebar-button {
    font-size: 1.25em;
  }

  .sidebar-button .button-label {
    font-size: 0.75rem;
  }

  .sidebar-top-section, 
  .sidebar-bottom-section {
    gap: 0.5rem;
  }

  .manage-access-modal {
    max-width: 600px;
  }

  .exports-modal {
    max-width: 600px;
  }
}

@media (max-width: 480px) {
  :root {
    --button-size: 2.25rem; /* Smaller buttons on very small screens */
  }
  
  .sidebar-button {
    font-size: 1rem;
  }
  
  .sidebar-top-section, 
  .sidebar-bottom-section {
    gap: 0.35rem;
  }
  
  .manage-access-modal {
    max-width: 350px;
  }

  .exports-modal {
    max-width: 360px;
  }
}

/* For very small heights */
@media screen and (max-height: 500px) {
  .sidebar-top-section, 
  .sidebar-bottom-section {
    gap: 0.25rem;
  }
  
  :root {
    --button-size: 2rem; /* Even smaller buttons on very small height screens */
  }
  
  .sidebar-content {
    padding: 0.5rem 0;
  }
  
  .sidebar-top-section {
    padding-top: 0.5rem;
  }
  
  .sidebar-bottom-section {
    padding-bottom: 0.5rem;
  }
  
  /* Make scrollable when needed */
  .sidebar-content {
    overflow-y: auto;
  }
}

