/* Reset Password Styles */
.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.reset-password-card {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
}

.logo-section {
    text-align: center;
    margin-bottom: 1.5rem;
}

.logo-section h1 {
    color: #333;
    font-size: 1.5rem;
    margin-top: 0.5rem;
}

.change-password-section {
    margin-top: 1rem;
}

.change-password-section h4 {
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
}

.reset-form .form-field {
    margin-bottom: 1.5rem;
}

.reset-form label {
    display: block;
    margin-bottom: 0.5rem;
    color: #6c757d;
    font-size: 0.9rem;
}

.p-password {
    display: flex !important;
    align-items: center !important;
    position: relative !important;
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
}

.p-password input {
    font-size: 0.9rem;
    width: 100% !important;
    min-width: auto !important;
    max-width: 100% !important;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0.5rem 0.75rem !important;
    box-sizing: border-box !important;
}

.p-password.p-invalid input {
    border-color: #E83636;
}

.p-error {
    color: #E83636;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

/* Button Styles - using login button style */
.reset-form .p-button,
.reset-submit-button {
    width: 100%;
    background: #2CB392;
    height: 40px;
    border: none;
    padding: 0.75rem;
    font-size: 1rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.reset-form .p-button:hover,
.reset-submit-button:hover {
    background: #259a7d;
}

.reset-form .p-button:disabled,
.reset-submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.reset-submit-button .p-button-icon {
    color: white !important;
    font-size: 1rem;
}

.reset-submit-button .p-button-label {
    color: white !important;
}

/* Links Section */
.links-section {
    margin-top: 1.5rem;
    text-align: center;
}

.links-section a {
    color: #32afc3;
    text-decoration: none;
    font-size: 0.8rem;
    display: block;
    margin-top: 0.5rem;
}

.links-section a:hover {
    text-decoration: underline;
}

/* Message Styles */
.floating-message {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}

/* Invalid Token Card */
.invalid-token-card {
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.invalid-token-card h2 {
    color: #333;
    margin-bottom: 1.5rem;
}

/* Toast styling - from login */
.p-toast {
    z-index: 9999 !important;
}

.p-toast-message {
    margin: 0 1rem;
}

.p-toast-top-right {
    top: 20px !important;
    right: 20px !important;
    position: fixed !important;
}

/* Ensure consistent styling for FloatLabel with Password */
.p-float-label {
    display: block;
    margin-bottom: 0.5rem;
}

.p-fluid .p-password {
    display: block !important;
    width: 100% !important;
    max-width: 400px !important;
    box-sizing: border-box !important;
}

.p-fluid .p-float-label .p-password {
    width: 100% !important;
}

.p-password input,
.password-input-constrained {
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
    padding-right: 2.5rem !important;
}

/* Responsive Adjustments */
@media (max-width: 480px) {
    .reset-password-card {
        padding: 1.5rem;
    }

    .logo-section h1 {
        font-size: 1.25rem;
    }

    .links-section {
        margin-top: 1rem;
    }

    .links-section a {
        font-size: 0.75rem;
    }
    
    .p-float-label {
        max-width: 100% !important;
        width: 100% !important;
    }
    
    .p-password {
        width: 100% !important;
        max-width: 100% !important;
        box-sizing: border-box !important;
    }
    
    .p-password input {
        width: 100% !important;
        min-width: auto !important;
        max-width: 100% !important;
        box-sizing: border-box !important;
    }
}