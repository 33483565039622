.page-layout {
  position: relative;
  min-height: 400px;
}

.page-content {
  position: relative;
  z-index: 1;
  width: 100%;
}

/* Add specific layout classes for different views */
.page-layout.dependencies-layout {
  padding-left: 45px;
  background-color: var(--surface-ground);
}

.page-layout.development-layout {
  padding-left: 45px;
  background-color: var(--surface-ground);
}

.page-layout.flags-layout {
  padding-left: 45px;
  background-color: var(--surface-ground);
}

.page-layout.files-layout {
  padding-left: 45px;
  background-color: var(--surface-ground);
}

.page-layout.audit-summary-layout {
  background-color: var(--surface-ground);
}

.page-layout.audit-summary-layout .page-content {
  padding: 1.5rem;
  margin-left: 45px;
}

/* Common styles for content sections */
.content-section {
  background: var(--surface-card);
  padding: 1.5rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .page-content {
    max-width: 600px;
  }
}

@media (max-width: 480px) {
  .page-content {
    max-width: 360px;
    margin-left: -20px;
  }
}

