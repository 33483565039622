.admin-users-page-layout {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--surface-ground);
}

.admin-users-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    min-height: 0; /* Required for Firefox */
    margin-left: 50px;
}

.admin-users-container h1 {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--primary-color);
    margin: 0 0 1.5rem 0;
    padding: 0;
}

.admin-users-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
}

/* Metrics Section */
.admin-metrics-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
}

.admin-metric-card {
    display: flex;
    flex-direction: column;
    background-color: var(--surface-card);
    border-radius: 8px;
    padding: 1.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    cursor: help;
}

.admin-metric-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--surface-hover);
}

.admin-metric-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.admin-metric-title {
    font-size: 0.875rem;
    color: var(--text-color-secondary);
    font-weight: 500;
}

.admin-tooltip-icon {
    color: var(--text-color-secondary);
    font-size: 0.875rem;
    margin-left: 0.5rem;
    cursor: help;
    transition: color 0.2s;
}

.admin-tooltip-icon:hover {
    color: var(--primary-color);
}

.admin-user-stat-value {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--text-color);
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.admin-user-stat-value .p-progress-spinner {
    width: 1.5rem !important;
    height: 1.5rem !important;
}

/* Users Table Section */
.admin-users-table-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.admin-users-card {
    background-color: var(--surface-card);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.admin-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--surface-border);
}

.admin-table-header h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-color);
}

.admin-search-container {
    position: relative;
}

.admin-search-input {
    width: 280px;
    padding-left: 2.5rem;
}

.admin-search-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-color-secondary);
}

/* DataTable Styling */
.p-datatable-users {
    width: 100%;
}

.p-datatable-users .p-datatable-thead > tr > th {
    background-color: var(--surface-section);
    color: var(--text-color);
    font-weight: 600;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--surface-border);
}

.p-datatable-users .p-datatable-tbody > tr {
    transition: background-color 0.2s;
}

.p-datatable-users .p-datatable-tbody > tr:hover {
    background-color: var(--surface-hover);
}

.p-datatable-users .p-datatable-tbody > tr > td {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--surface-border);
}

/* User-specific styles */
.user-name {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.user-name-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 600;
}

.user-name-details {
    display: flex;
    flex-direction: column;
}

.user-full-name {
    color: var(--text-color);
    font-weight: 500;
}

.user-email {
    color: var(--text-color-secondary);
    font-size: 0.875rem;
}

.user-admin-icon {
    display: flex;
    justify-content: center;
}

.user-admin-icon i {
    font-size: 1.25rem;
}

.user-audit-count {
    font-variant-numeric: tabular-nums;
    text-align: right;
    font-weight: 500;
}

/* Paginator Styling */
.admin-users-paginator {
    border-top: 1px solid var(--surface-border);
    padding: 0.75rem 1.5rem;
}

.admin-users-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: var(--primary-color);
    color: white;
}

/* Loading State */
.admin-users-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.admin-users-loader {
    width: 4rem;
    height: 4rem;
    border: 4px solid var(--surface-border);
    border-top: 4px solid var(--primary-color);
    border-radius: 50%;
    animation: admin-users-loader 1.5s infinite linear;
}

@keyframes admin-users-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Responsive adjustments */
@media screen and (max-width: 992px) {
    .admin-metrics-section {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        gap: 0.75rem;
    }
    
    .admin-metric-card {
        padding: 1rem;
    }
    
    .admin-user-stat-value {
        font-size: 1.5rem;
    }
    
    .p-datatable-users .p-datatable-thead > tr > th,
    .p-datatable-users .p-datatable-tbody > tr > td {
        padding: 0.75rem 1rem;
    }
}

@media screen and (max-width: 768px) {
    .admin-users-container {
        padding: 1.5rem 1rem;
    }
    
    .admin-metrics-section {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    
    .admin-table-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
        padding: 1rem;
    }
    
    .admin-search-container {
        width: 100%;
    }
    
    .admin-search-input {
        width: 100%;
    }
    
    .user-name-avatar {
        width: 28px;
        height: 28px;
        font-size: 0.75rem;
    }
    
    .user-email {
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 576px) {
    .admin-metrics-section {
        grid-template-columns: 1fr;
    }
    
    .user-name {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
    
    .user-name-details {
        width: 100%;
    }
}
