.score-graph-indicator {
    border-radius: 50%;
    background-color: #2CB392;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -17px;
    z-index: 2;
}

.score-graph-indicator.sub-chapter {
    top: -13px;
}

.score-bar {
    width: 100%;
    height: 8px;
    background-color: #E0E0E0;
    border-radius: 8px;
    position: relative;
    margin-left: 18px;
  }

  .avg-line {
    transition: all 0.3s ease;
  }

.help-button {
  position: absolute;
  left: -30px;
  top: 7px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: auto !important;
}

.help-button:hover {
  opacity: 0.8;
  border: none;
  background-color: transparent;
}

.help-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(100, 116, 139, 0.2);
  border-radius: 50%;
}
