/* Flags.css */
  /* General styles */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
  }
  
  /* Ensure full height for the container and font settings */
  html, body, #root, .App {
    height: 100%;
  }

  .flags-page-layout {
    display: flex;
    flex-direction: column; 
    padding: 20px;
    padding-left: 45px;
    gap: 20px;
    background-color: #F9F9F9;
    justify-content: center;
    height: 100%;
  }

  .flag-header {
    margin-left: 35px;
  }
  
  .flag-descriptions {
    margin-left: 1rem;
    margin-top: 8px;
    margin-bottom: 20px;
    line-height: 1.6;
  }

  .flag-descriptions p {
    margin-bottom: 8px;
    color: #4B4B4B;
  }

  .flags-container {
    margin-left: 35px;
    margin-bottom: 40px;
    border-radius: 8px;
    border-color: #DFDFDF;
    padding-right: 40px;
    width: 100%;

  }

  .flags-header {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 6px;
  }

  .flags-content {
    border-radius: 6px;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    border-color: #DFDFDF;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding-bottom: 4rem;
    
  }

  .flag-table {
    background-color: var(--surface-card);
    border-radius: 6px;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

 /* Style the headers of the DataTable */
.p-datatable .p-datatable-thead > tr > th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

/* Style the rows of the DataTable */
.p-datatable .p-datatable-tbody > tr {
  border-bottom: 1px solid #ddd;
}

.p-datatable .p-datatable-tbody > tr:nth-child(odd) {
  background-color: #fafafa;
}

/* Style the cells */
.p-datatable .p-datatable-tbody > tr > td {
  padding: 10px 20px;
  min-height: 50px; /* Ensure minimum height for each row */
  white-space: normal; /* Allow text to wrap */
}

/* Style for specific columns if needed */
.purpose-column {
  word-break: break-word; /* Break long words to prevent overflow */
}

.flags-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; /* Set the height */
  width: 100%; /* Take full width of the container */
  padding: 0; /* Remove padding if any */
}

.flags-card .p-message {
  flex-grow: 1;
  margin: 0; /* Remove margin */
  border-radius: 0; /* Optional: removes border-radius */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.flags-card .p-message .p-message-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag-description-button {
  background: none;
  border: none;
  margin-left: -20px;
  margin-top: -10px;
  width: auto;
}

.flag-description-button .p-button-label{
  color: #32AFC3;
  font-size: 16px;
  background: none;
}

.flag-description-button:hover {
  background: none;
  border: none;
}

.flags-graph-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-left: 35px;
  width: calc(100% - 70px);
  padding: 20px 0;
}

.sunburst-container {
  grid-column: 1 / -1; /* Take full width by default */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sunburst-container svg {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.time-to-fix-total-container,
.time-to-fix-domain-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding-bottom: 1rem;
}

.sunburst-header-container {
  width: 100%;
  margin-bottom: 10px;
  padding: 1rem 1.5rem;
  text-align: center;
  background-color: #F9FAFB;
  border-bottom: 1px solid #F3F4F6;
}

.sunburst-header-container h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #4B5563;
  margin-bottom: 0.5rem;
}

.sunburst-description {
  font-size: 0.9rem;
  color: #6B7280;
  margin: 0;
}

/* For larger screens, allow sunburst to share row with time graphs */
@media screen and (min-width: 1200px) {
  .flags-graph-container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
      "sunburst total"
      "sunburst domain";
  }

  .sunburst-container {
    grid-area: sunburst;
    height: 100%;
  }

  .time-to-fix-total-container {
    grid-area: total;
  }

  .time-to-fix-domain-container {
    grid-area: domain;
  }
}

/* For medium screens, stack everything vertically */
@media screen and (max-width: 1199px) {
  .flags-graph-container {
    grid-template-columns: 1fr;
  }

  .sunburst-container,
  .time-to-fix-total-container,
  .time-to-fix-domain-container {
    grid-column: 1 / -1;
  }

  .sunburst-container svg {
    max-width: 400px;
  }
}

/* For mobile screens */
@media screen and (max-width: 600px) {
  .flags-graph-container {
    margin-left: 15px;
    width: calc(100% - 30px);
    gap: 20px;
  }

  .sunburst-container svg {
    max-width: 350px;
  }
  
  .sunburst-header-container {
    padding: 0.75rem 1rem;
  }
  
  .sunburst-header-container h3 {
    font-size: 1rem;
  }
  
  .sunburst-description {
    font-size: 0.8rem;
  }
}

.file-loader {
  width: 40px;
  height: 40px;
  --c:no-repeat linear-gradient(#32AFC3 0 0);
  background: var(--c),var(--c),var(--c),var(--c);
  background-size: 21px 21px;
  animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
}
  
@keyframes l5 {
  0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
  33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
  66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
  100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}
  
.file-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding-top: 50vh;
  transform: translateY(-50%);
}

@media (max-width: 480px) {
  .flags-container{
    padding-right: 0;
  }
}

.flags-graph-disclaimer {
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #F9FAFB;
  border-left: 3px solid #E5E7EB;
  color: #6B7280;
  font-size: 0.85rem;
  font-style: italic;
  border-radius: 4px;
  grid-column: 1 / -1;
  width: 100%;
}
