.registration-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.registration-card {
    width: 100%;
    max-width: 500px; /* Reduced from 500px for a more compact look */
    padding: 2rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
}

.registration-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.registration-header h1 {
    color: #333;
    font-size: 1.5rem; /* Reduced from 24px */
    margin-bottom: 0.5rem;
}

.registration-header h3 {
    color: #8D8D8D;
    font-size: 0.9rem; /* Reduced from 16px */
    font-weight: normal;
    line-height: 1.4;
}

.p-field {
    margin-bottom: 1.5rem;
}

.p-fluid .p-field .p-inputtext,
.p-fluid .p-field .p-password {
    font-size: 0.9rem;
    width: 100% !important;
    border: 1px solid #ced4da;
    border-radius: 4px;
    max-width: 400px;
    box-sizing: border-box !important;
}

.p-fluid .p-field .p-password input {
    border: none;
    width: 100% !important;
    padding: 0.5rem 0.75rem;
    box-sizing: border-box !important;
    max-width: 100% !important;
}

.p-float-label {
    display: block;
    margin-bottom: 0.5rem;
}

.p-float-label label {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    color: #6c757d;
    font-size: 0.9rem;
    pointer-events: none;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
    top: -0.75rem;
    font-size: 0.75rem;
    background-color: white;
    padding: 0 0.25rem;
    left: 0.5rem;
}

.p-float-label input:placeholder-shown:not(:focus) ~ label {
    top: 50%;
    font-size: 0.9rem;
    transform: translateY(-50%);
}

.p-inputtext, .p-password input {
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
}

.p-password {
    display: block !important;
    width: 100% !important;
    max-width: 400px !important;
    box-sizing: border-box !important;
}

.p-fluid .p-password {
    display: block !important;
    width: 100% !important;
    max-width: 400px !important;
    box-sizing: border-box !important;
}

.p-fluid .p-float-label .p-password {
    width: 100% !important;
}

.p-password input,
.password-input-constrained {
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
    padding-right: 2.5rem !important;
}

.start-audit-button {
    margin-top: 1rem;
    width: 100%;
    background: #2CB392;
    border: none;
    padding: 0.75rem;
    font-size: 1rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.start-audit-button:hover {
    background: #259a7d;
}

.start-audit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.start-audit-button .p-button-icon {
    color: white !important;
    font-size: 1rem;
}

.start-audit-button .p-button-label {
    color: white !important;
}

.p-invalid .p-inputtext,
.p-invalid .p-password input {
    border-color: #E83636;
}

.p-error {
    color: #E83636;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

::placeholder {
    color: #8D8D8D;
}

.terms-text {
    font-size: 0.75rem;
    color: #8D8D8D;
    margin-top: 1rem;
    text-align: center;
    line-height: 1.5;
}

.terms-text a {
    color: #C049DE;
    text-decoration: none;
    font-size: 0.75rem;
}

.terms-text a:hover {
    text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .registration-card {
        padding: 1.5rem;
    }

    .registration-header h1 {
        font-size: 1.25rem;
    }

    .registration-header h3 {
        font-size: 0.85rem;
    }
    
    .p-fluid .p-field .p-inputtext,
    .p-fluid .p-field .p-password,
    .p-float-label {
        max-width: 100% !important;
        width: 100% !important;
        box-sizing: border-box !important;
    }
    
    .p-fluid .p-field .p-password input {
        width: 100% !important;
        max-width: 100% !important;
        box-sizing: border-box !important;
    }
    
    .p-password {
        max-width: 100% !important;
        width: 100% !important;
        box-sizing: border-box !important;
    }
}