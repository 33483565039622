.formatted-text {
  font-size: 14px;
  line-height: 1.6;
  color: #4B4B4B;
}

.formatted-text-line {
  margin-bottom: 8px;
}

.formatted-text-line.section-line {
  font-weight: 600;
  color: #323232;
  margin-top: 16px;
  margin-bottom: 12px;
}

.formatted-text-line.bullet-line {
  padding-left: 20px;
  position: relative;
}

.formatted-text-line.bullet-line::before {
  content: "•";
  position: absolute;
  left: 8px;
  color: #32AFC3;
}

.formatted-text-line.sub-bullet-line {
  padding-left: 40px;
  position: relative;
  font-size: 18px;
}

.formatted-text-line.sub-bullet-line::before {
  content: "◦";
  position: absolute;
  left: 28px;
  color: #32AFC3;
}

.formatted-text strong {
  color: #323232;
  font-weight: 600;
}

/* Markdown heading styles */
.formatted-text-h1 {
  font-size: 24px;
  font-weight: 700;
  color: #1a1a1a;
  margin-top: 24px;
  margin-bottom: 16px;
}

.formatted-text-h2 {
  font-size: 20px;
  font-weight: 600;
  color: #232323;
  margin-top: 20px;
  margin-bottom: 12px;
}

.formatted-text-h3 {
  font-size: 18px;
  font-weight: 600;
  color: #2d2d2d;
  margin-top: 16px;
  margin-bottom: 8px;
} 