* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
  }

/* Ensure sidebar stays open and visible */
.p-sidebar {
    transition: transform 0.3s ease;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

/* Handle responsive behavior */
@media screen and (max-width: 768px) {
    .p-sidebar {
        width: 260px !important; /* Smaller width on mobile */
    }
    
    .p-sidebar.p-component-overlay {
        /* Keep a small portion visible on collapse */
        transform: translateX(calc(100% - 30px)) !important;
    }
    
    .p-sidebar.p-component-overlay:hover,
    .p-sidebar.p-component-overlay:focus {
        /* Expand on hover or focus */
        transform: translateX(0) !important;
    }
}

.p-sidebar-header .p-sidebar-close {
    display: none;
}

.audit-sidebar-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.confirm-button {
    margin-top: 50px;
    width: 95%;
}

.audit-summary {
    margin-top: 45px;
    padding-left: 10px;
}

.files-to-audit, .lines-of-code {
    margin-bottom: 10px;
}

.audit-order{
    margin-top: 50px;
}

.start-audit-button {
    margin-top: 15px;
    margin-left: -5px;
    width: 100%; 
    background: #2CB392;
}

.start-audit-button .p-button-label {
    color: #fff;     
}

.start-audit-button:hover .p-button-label {
    color: #343A40;
    border-color: #2CB392;
}

.audit-info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-right: 10px;
}

.audit-info-row-small {
    font-size: 12px;
    font-style: italic;
    color: #8D8D8D;
}

.audit-value {
    font-weight: bold;
}

.audit-divider {
    border: 0.5px solid #ccc; /* Grey thin line */
    margin: 10px 10px 10px 0px;
}

.audit-warning {
    color: #E83636;
    margin-top: 10px;
}

.start-audit-button.disabled {
    background-color: #ccc; /* Example styling for disabled button */
}

.checkout-button {
    background-color: #32AFC3;
    color: white;
    min-width: 95%;
    margin-top: 1rem;
}

.checkout-button .p-button-label {
    color: white;
}

.checkout-button .p-button-icon {
    color: white;
}

.checkout-button:hover .p-button-label {
    color: #343A40;
    border-color: #32AFC3;
}

.checkout-button:hover {
    background-color: white;
    border-color: #343A40;
}

.checkout-button:hover .p-button-icon {
    color: #32AFC3;
}

.checkout-button:hover .p-button-label {
    color: #32AFC3
}

.talk-to-sales-button:hover {
    background-color: transparent !important;
    color: #323232 !important;
}

.talk-to-sales-button:hover .p-button-icon {
    color: #323232 !important;
}

.talk-to-sales-button:hover .p-button-label {
    color: #323232 !important;
}

.talk-to-sales-button .p-button-label {
    color: #fff !important;
}

.talk-to-sales-button .p-button-icon {
    color: #fff !important;
}