.badge-helper-content {
    padding: 1rem;
}

.badge-preview {
    background-color: #f8f9fa;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.static-badge-options {
    background-color: #f2f7ff;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem 0;
    border-left: 4px solid #32AFC3;
}

.static-badge-options p {
    margin-bottom: 1rem;
}

.download-buttons {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1rem;
}

.badge-download-button {
    min-width: 240px;
    height: 40px;
}

.badge-code {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 1rem 0;
}

.badge-code .p-inputtext {
    font-family: monospace;
    background-color: #f8f9fa;
}

.badge-instructions {
    margin-top: 1.5rem;
}

.badge-instructions ol {
    padding-left: 1.5rem;
    margin: 1rem 0;
}

.badge-instructions li {
    margin: 0.5rem 0;
    color: #495057;
}

.badge-instructions p {
    color: #666;
    margin-top: 0.75rem;
}

.badge-instructions .note {
    font-style: italic;
    color: #777;
    font-size: 0.9rem;
    margin-top: 1rem;
}

.badge-instructions code {
    background-color: #f1f1f1;
    padding: 0.1rem 0.3rem;
    border-radius: 3px;
    font-family: monospace;
}

.gitlab-instructions {
    border-left: 4px solid #FC6D26;
    padding-left: 1rem;
} 