.admin-dashboard-page-layout {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--surface-ground);
    min-height: 100vh;
}

.admin-dashboard-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    min-height: 0; /* Required for Firefox */
    margin-left: 50px;
}

.admin-dashboard-header {
    margin-bottom: 2rem;
}

.admin-dashboard-header h1 {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--primary-color);
    margin: 0 0 0.5rem 0;
}

.admin-dashboard-header p {
    color: var(--text-color-secondary);
    margin: 0;
    font-size: 0.875rem;
}

.admin-dashboard-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
}

/* Dashboard Cards Section */
.admin-dashboard-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
}

.admin-dashboard-card {
    background-color: var(--surface-card);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: all 0.2s ease;
}

.admin-dashboard-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-dashboard-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    border-bottom: 1px solid var(--surface-border);
    flex-wrap: wrap;
    gap: 1rem;
}

.admin-dashboard-card .card-header h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
    color: var(--text-color);
}

/* Time Period Controls */
.time-period-buttons {
    display: flex;
    gap: 0.5rem;
}

.time-period-buttons .p-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
}

.time-period-buttons .p-button.p-button-outlined {
    background-color: var(--surface-card);
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.time-period-buttons .p-button.p-button-outlined:hover {
    background-color: var(--primary-50);
}

/* Graph Container */
.admin-dashboard-graph-container {
    padding: 1.5rem;
    min-height: 400px;
}

/* Loading States */
.admin-dashboard-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.admin-dashboard-loader .p-progress-spinner {
    width: 4rem !important;
    height: 4rem !important;
}

.admin-dashboard-error {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    color: var(--red-500);
    text-align: center;
    padding: 1rem;
}

.no-data-message {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    color: var(--text-color-secondary);
    font-style: italic;
    text-align: center;
}

/* Tooltip Styling */
.admin-dashboard-tooltip {
    background-color: var(--surface-900);
    color: var(--surface-0);
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    font-size: 0.875rem;
}

/* Responsive Design */
@media screen and (max-width: 992px) {
    .admin-dashboard-container {
        padding: 1.5rem;
    }
    
    .time-period-buttons {
        flex-wrap: wrap;
    }
    
    .admin-dashboard-graph-container {
        padding: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .admin-dashboard-container {
        padding: 1rem;
    }
    
    .admin-dashboard-card .card-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
    }
    
    .time-period-buttons {
        width: 100%;
        justify-content: space-between;
    }
    
    .time-period-buttons .p-button {
        flex: 1;
        font-size: 0.75rem;
        padding: 0.5rem;
    }
}

@media screen and (max-width: 576px) {
    .admin-dashboard-header {
        margin-bottom: 1.5rem;
    }
    
    .admin-dashboard-header h1 {
        font-size: 1.5rem;
    }
    
    .admin-dashboard-graph-container {
        min-height: 300px;
    }
}
