.score-explanation-modal {
  width: 90%;
  min-width: auto;
  max-width: 1200px;
  height: auto;
  margin: 1rem;
}

.score-explanation-modal .p-dialog-header {
  border-bottom: none;
  background-color: #f8f9fa;
}

.score-explanation-modal .modal-content {
  padding: 1.25rem;
}

/* Major Point Specific Styles */
.score-explanation-modal.major-point .p-dialog-header {
  background-color: #32AFC3;
  color: white;
}

.score-explanation-modal.major-point .p-dialog-header .p-dialog-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}

.score-explanation-modal.major-point .p-dialog-header .p-dialog-header-icon {
  color: white;
}

.score-explanation-modal.major-point .score-explanation-subpoints {
  margin-top: 2rem;
  background-color: #f0f9fa;
  border: 1px solid #32AFC3;
  border-radius: 8px;
  padding: 1.5rem;
}

.score-explanation-modal.major-point .score-explanation-subpoints h4 {
  color: #32AFC3;
  font-size: 1.1rem;
  margin-bottom: 1.25rem;
}

.score-explanation-modal.major-point .subpoint-item {
  border: 1px solid #e2e8f0;
  background-color: white;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.score-explanation-modal.major-point .subpoint-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(50, 175, 195, 0.1);
}

/* Score Display */
.score-explanation-score {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e2e8f0;
}

.score-explanation-label {
  font-weight: 600;
  color: #495057;
}

.score-explanation-value {
  font-size: 1.25rem;
  font-weight: bold;
  color: #2CB392;
}

/* Sections */
.score-explanation-section {
  margin-bottom: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.score-explanation-section h3 {
  color: #1e293b;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.score-explanation-text {
  color: #475569;
  line-height: 1.6;
}

/* Examples */
.score-explanation-examples {
  margin-top: 1rem;
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 1rem;
}

.score-explanation-examples h4 {
  color: #64748b;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
}

.score-explanation-example {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
}

.score-explanation-example code {
  color: #334155;
  font-family: monospace;
  white-space: pre-wrap;
}

/* Separator */
.score-explanation-separator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  position: relative;
}

.score-explanation-separator::before,
.score-explanation-separator::after {
  content: '';
  position: absolute;
  height: 1px;
  background-color: #32AFC3;
  width: 40%;
}

.score-explanation-separator::before {
  left: 0;
}

.score-explanation-separator::after {
  right: 0;
}

.score-explanation-separator svg {
  color: #32AFC3;
}

/* Business Impact Section */
.score-explanation-business {
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 1.5rem;
}

/* Subpoints Grid */
.score-explanation-subpoints {
  margin-top: 1.5rem;
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 1.5rem;
}

.score-explanation-subpoints h4 {
  color: #64748b;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
}

.subpoints-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.subpoint-item {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.subpoint-name {
  color: #1e293b;
  font-weight: 600;
  font-size: 0.9rem;
}

.subpoint-description {
  color: #64748b;
  font-size: 0.85rem;
  line-height: 1.4;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .score-explanation-modal .modal-content {
    padding: 0.75rem;
  }
  
  .score-explanation-separator::before,
  .score-explanation-separator::after {
    width: 35%;
  }

  .subpoints-grid {
    grid-template-columns: 1fr;
  }
}

.score-explanation-references {
  margin-top: 1rem;
}

.references-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-size: 0.9rem;
}

.reference-item {
  display: flex;
  gap: 0.5rem;
  line-height: 1.4;
}

.reference-number {
  flex-shrink: 0;
  color: #666;
  font-weight: 500;
}

.reference-text {
  color: #444;
}

.reference-link {
  color: #2563eb;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.reference-link:hover {
  color: #1d4ed8;
  text-decoration: underline;
}

/* References Section */
.references-separator {
  margin: 1.5rem 0;
}

.references-separator .separator-text {
  background-color: white;
  padding: 0 1rem;
  color: #32AFC3;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.score-explanation-references {
  margin-top: 0.5rem;
  padding: 1rem;
}

.references-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.8rem;
}

.reference-item {
  display: flex;
  gap: 0.5rem;
  line-height: 1.4;
}

.reference-number {
  flex-shrink: 0;
  color: #666;
  font-weight: 500;
  font-size: 0.8rem;
}

.reference-text {
  color: #444;
  font-size: 0.8rem;
}

.reference-link {
  color: #32AFC3;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
  font-size: 0.8rem;
}

.reference-link:hover {
  color: #32AFC3;
  text-decoration: underline;
}

/* Update other font sizes to be smaller */
.score-explanation-section h3 {
  font-size: 1.1rem;
}

.score-explanation-examples h4 {
  font-size: 0.9rem;
}

.score-explanation-example code {
  font-size: 0.85rem;
}

.subpoint-name {
  font-size: 0.85rem;
}

.subpoint-description {
  font-size: 0.8rem;
} 