.dependencies-page-layout {
    display: flex;
    flex-direction: column; 
    padding: 20px;
    padding-left: 45px;
    gap: 20px;
    background-color: #F9F9F9;
    justify-content: center;
    height: 100%;
  }


  .dependency-header {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 6px;
  }
  
  .dependencies-container {
    margin-left: 35px;
    margin-bottom: 40px;
    border-radius: 8px;
    border-color: #DFDFDF;
  }

  /* Styles for the new div structure replacing Panel */
  .dependency-graph {
    margin-top: 35px;
    margin-bottom: 40px;
    border-radius: 8px;
    border: 1px solid #DFDFDF;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    overflow: hidden;
  }

  .graph-header {
    padding: 1rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #DFDFDF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .graph-header:hover {
    background-color: #f1f1f1;
  }

  .graph-header h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #495057;
  }

  .dependencies-graph-panel {
    padding: 0;
    min-height: 400px;
  }

.dependencygraph-loader {width: 40px;
height: 40px;
--c:no-repeat linear-gradient(#32AFC3 0 0);
background: var(--c),var(--c),var(--c),var(--c);
background-size: 21px 21px;
animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
}

@keyframes l5 {
0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}

.dependency-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem; /* Padding around the loader */
  width: 100%;
  height: 100%;
}

.unused-packages-warning {
  background-color: #fff7ed;
  border-left: 4px solid #f59e0b;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 4px;
}

.badge-container {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

/* Enhanced badge styling */
.badge-container .p-badge {
  font-size: 0.8rem;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
}

/* Status badges styling to match the graph */
.badge-container .status-badge {
  /* Override PrimeReact styles to match graph colors */
  color: white !important;
  border: none !important;
}

/* Ensure custom badge colors take precedence over PrimeReact severity colors */
.badge-container .p-badge.p-badge-success,
.badge-container .p-badge.p-badge-info,
.badge-container .p-badge.p-badge-warning,
.badge-container .p-badge.p-badge-danger,
.badge-container .p-badge.p-badge-secondary {
  border: none !important;
}

.badge-tooltip-container {
  position: relative;
  display: inline-block;
}

.badge-tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
}

.badge-tooltip-container:hover .badge-tooltip {
  visibility: visible;
}