/* Main container */
.cs-checkout-stepper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

/* Stepper component */
.cs-stepper {
    margin-bottom: 20px;
}

/* Organization step */
.cs-organization-content {
    padding: 10px 0;
}

.cs-loading-container {
    text-align: center;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.cs-form-title {
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
}

.cs-existing-orgs {
    margin-bottom: 20px;
}

.cs-new-org-button {
    border: 1px dashed #999;
    border-radius: 8px;
    padding: 15px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    color: #555;
    font-weight: 500;
}

.cs-new-org-button:hover {
    border-color: #32AFC3;
    color: #32AFC3;
    background-color: rgba(50, 175, 195, 0.05);
}

.cs-new-org-form {
    margin-top: 15px;
}

.cs-card-container {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
}

/* Payment step */
.cs-payment-step {
    padding: 10px 0;
}

.cs-order-summary {
    margin-bottom: 25px;
}

.cs-order-details {
    padding: 5px;
}

.cs-order-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 0.95rem;
}

.cs-order-row.cs-total {
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid #eee;
    font-weight: 600;
    font-size: 1.1rem;
}

.cs-highlight {
    color: #32AFC3;
}

.cs-payment-loading,
.cs-payment-error,
.cs-payment-success,
.cs-payment-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

/* Form elements */
.cs-organization-form {
    padding: 20px;
}

.cs-form-row {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-bottom: 8px;
    align-items: flex-start;
}

.cs-form-row .cs-field {
    flex: 1;
    margin-bottom: 0;
}

.cs-field {
    margin-bottom: 1rem;
}

.cs-form-row .cs-field label {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 0.9rem;
}

.cs-form-row .cs-field .p-inputtext,
.cs-form-row .cs-field .p-dropdown {
    width: 100%;
    height: 40px;
    border-radius: 6px;
}

.cs-form-row .cs-field .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 1px rgba(50, 175, 195, 0.2) !important;
    border-color: #32AFC3 !important;
}

.cs-form-row .cs-field .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 1px rgba(50, 175, 195, 0.2) !important;
    border-color: #32AFC3 !important;
}

.cs-form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    padding: 20px;
    border-top: 1px solid #f0f0f0;
}

.cs-form-actions .p-button {
    min-width: 120px;
}

.cs-required-field:after {
    content: "*";
    color: #e24c4c;
    margin-left: 4px;
    font-size: 1.1em;
    font-weight: bold;
}

.cs-field-error {
    color: #e24c4c;
    font-size: 0.85rem;
    margin-top: 4px;
    display: block;
    animation: cs-shake 0.4s ease-in-out;
}

.p-invalid {
    border-color: #e24c4c !important;
}

@keyframes cs-shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
}

.cs-checkout-stepper .p-stepper {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
}

.cs-checkout-stepper .p-stepper-panel-content {
    background-color: #fff;
    padding: 20px;
}

.cs-checkout-stepper .p-stepper-header {
    padding: 1.2rem 1.5rem;
    background-color: #f8f9fa;
}

.cs-checkout-stepper .p-stepper-step {
    position: relative;
}

.cs-checkout-stepper .p-stepper-step .p-stepper-number {
    width: 36px;
    height: 36px;
    font-size: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #32AFC3;
    color: #495057;
    border: 2px solid #e9ecef;
    font-weight: 600;
    margin-right: 8px;
}

.cs-checkout-stepper .p-stepper-step .p-stepper-title {
    font-size: 16px;
    font-weight: 500;
    color: #495057;
}

.cs-checkout-stepper .p-stepper-step.p-highlight .p-stepper-number {
    background-color: #32AFC3;
    color: #ffffff;
    border-color: #32AFC3;
}

.cs-checkout-stepper .p-stepper-step.p-disabled .p-stepper-number,
.cs-checkout-stepper .p-stepper-step.p-disabled .p-stepper-title {
    opacity: 0.6;
}

.cs-checkout-stepper .p-stepper-step.p-highlight .p-stepper-title {
    color: #32AFC3;
    font-weight: 600;
}

.cs-organization-form-card-container {
    background-color: none;
    border: none;
}

.cs-org-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 18px;
    margin-bottom: 12px;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.cs-org-card:hover {
    border-color: #32AFC3;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cs-org-card.cs-selected {
    border-color: #32AFC3;
    background-color: rgba(50, 175, 195, 0.05);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cs-org-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cs-org-card-body {
    font-size: 0.9rem;
    color: #555;
}

.cs-org-item {
    margin-bottom: 5px;
}

/* Add more form styles to better support validation */
.cs-checkout-stepper .p-inputtext.p-invalid,
.cs-checkout-stepper .p-dropdown.p-invalid {
    border-color: #E83636 !important;
}

.cs-checkout-stepper .p-inputtext.p-invalid:hover,
.cs-checkout-stepper .p-dropdown.p-invalid:hover {
    border-color: #E83636 !important;
}

.cs-checkout-stepper .p-inputtext.p-invalid:focus,
.cs-checkout-stepper .p-dropdown.p-invalid:focus {
    box-shadow: 0 0 0 1px rgba(232, 54, 54, 0.25) !important;
    border-color: #E83636 !important;
}

/* Enhance form appearance */
.cs-checkout-stepper .p-inputtext::placeholder,
.cs-checkout-stepper .p-dropdown .p-dropdown-label.p-placeholder {
    color: #aaa;
    opacity: 0.7;
}

.cs-checkout-stepper .p-inputtext:enabled:hover,
.cs-checkout-stepper .p-dropdown:not(.p-disabled):hover {
    border-color: #32AFC3;
}

/* Style for valid inputs */
.cs-checkout-stepper .p-inputtext:enabled:focus:not(.p-invalid),
.cs-checkout-stepper .p-dropdown:not(.p-disabled).p-focus:not(.p-invalid) {
    border-color: #32AFC3 !important;
    box-shadow: 0 0 0 1px rgba(50, 175, 195, 0.2) !important;
}

/* Order summary styling */
.cs-order-summary {
    margin-bottom: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !important;
    border-radius: 8px !important;
    border: none !important;
}

.cs-order-summary .p-card-body {
    padding: 1.25rem;
}

.cs-order-summary .p-card-content {
    padding: 0;
}

.cs-order-details {
    padding: 0 10px;
}

.cs-order-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.cs-order-row.cs-total {
    font-weight: bold;
    padding-top: 10px;
    border-top: 1px solid #eee;
    font-size: 1.1rem;
}

.cs-highlight {
    color: #32AFC3;
}

/* Loading states */
.cs-loading-container, 
.cs-payment-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin: 10px 0;
}

.cs-loading-container .p-progress-spinner,
.cs-payment-loading .p-progress-spinner {
    width: 50px !important;
    height: 50px !important;
}

.cs-payment-error-container {
    padding: 20px;
    background-color: #fff9f9;
    border-radius: 8px;
    margin: 10px 0;
    text-align: center;
}

.cs-payment-note {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px;
    font-size: 0.8rem;
    color: #666;
    background-color: #f8f9fa;
    text-align: center;
    font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .cs-form-row {
        flex-direction: column;
        gap: 8px;
    }
    
    .cs-form-row .cs-field {
        width: 100%;
    }
}

.cs-checkout-stepper .p-dropdown .p-dropdown-label {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
}

/* Stripe container styling */
.cs-stripe-container {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}

/* Custom stepper navigation */
.cs-stepper-header {
    margin-bottom: 20px;
    border-bottom: 1px solid #e9ecef;
    background-color: #f8f9fa;
}

.cs-stepper-tabs {
    display: flex;
    flex-wrap: wrap;
}

.cs-stepper-tab {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    position: relative;
    transition: color 0.2s;
}

.cs-stepper-tab.cs-active {
    color: #32AFC3;
    font-weight: 600;
}

.cs-stepper-tab.cs-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #32AFC3;
}

.cs-stepper-number {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f0f0f0;
    color: #555;
    font-weight: 600;
}

.cs-stepper-tab.cs-active .cs-stepper-number {
    background-color: #32AFC3;
    color: white;
}

.cs-stepper-title {
    font-size: 1rem;
}

.cs-stepper-content {
    padding: 20px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
}

.cs-stepper-tab.cs-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.cs-stepper-tab.cs-disabled .cs-stepper-number {
    background-color: #ddd;
    color: #999;
}

/* Price breakdown styles */
.cs-breakdown-toggle {
    cursor: pointer;
    color: #555;
    font-size: 0.9rem;
    padding: 5px 0;
    margin: 8px 0;
    transition: color 0.2s;
    display: flex;
    align-items: center;
}

.cs-breakdown-toggle:hover {
    color: #32AFC3;
}

.cs-breakdown-toggle i {
    margin-right: 5px;
    font-size: 0.85rem;
}

.cs-order-summary .pb-container {
    margin: 5px 0 15px 0;
    background-color: #fafbfc;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
}

.cs-price-breakdown {
    background-color: #f9f9f9;
    border-radius: 6px;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #eee;
}

.cs-price-breakdown h4 {
    margin: 0 0 10px 0;
    font-size: 0.95rem;
    color: #555;
    font-weight: 600;
}

.cs-breakdown-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 0.9rem;
}

.cs-breakdown-subtotal {
    margin-top: 5px;
    padding-top: 8px;
    border-top: 1px dashed #ddd;
    font-weight: 500;
}

.cs-breakdown-tax {
    padding-bottom: 8px;
    font-weight: 500;
    color: #666;
}

/* Add styling for disabled tabs when returning from Stripe */
.cs-stepper-tab.cs-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

/* Ensure the active tab has higher z-index and stands out */
.cs-stepper-tab.cs-active {
    background-color: #f8f9fa;
    color: #32AFC3;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
    position: relative;
}

@media (max-width: 768px) {
    .cs-price-breakdown {
        display: none;
    }

    .calculator-results {
        max-width: 500px;
    }
}

@media (max-width: 480px) {
    .calculator-results {
        max-width: 300px;
    }
}