/* demoscoregraph.css */
.score-graph-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    position: relative;
  }
  
  .chapter-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }
  
  .main-row {
    z-index: 5;
  }
  
  .sub-row {
    opacity: 0.9;
    position: relative;
    z-index: 15;
  }
  
  .sub-row:hover {
    z-index: 20;
  }
  
  .score-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    animation: fadeInUp 0.5s ease-out forwards;
    animation-delay: var(--row-delay);
    transition: background-color 0.2s ease;
    padding: 0.5rem;
    position: relative;
    z-index: 2;
  }
  
  .score-row:hover {
    background: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    z-index: 3;
  }
  
  .score-title {
    color: #C049DE;
    font-weight: bold;
    flex: 0 0 auto;
    width: 20%;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .main-title {
    color: #C049DE;
    min-width: 170px;
  }
  
  .sub-title {
    color: #8D8D8D;
    font-weight: 500;
    min-width: 170px;
  }
  
  .score-slider-graph {
    flex: 1;
    padding-bottom: 10px;
    position: relative;
    margin-left: 15px;
  }
  
  .subpoints-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  
  .score-tooltip {
    position: absolute;
    background-color: #333;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 0.9rem;
    z-index: 1000;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease;
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
    white-space: nowrap;
    visibility: hidden;
    min-width: 100px;
  }
  
  .score-slider-graph:hover .score-tooltip {
    opacity: 1;
    visibility: visible;
  }
  
  .tooltip-value {
    font-weight: normal;
    color: white;
    white-space: nowrap;
  }
  
  .tooltip-description {
    font-size: 0.85rem;
    line-height: 1.4;
    color: #f0f0f0;
  }
  
  /* Add a bottom-pointing arrow to the score tooltip */
  .score-tooltip::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -6px;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 450px) {
    .score-bar {
      /* Hide the score bar on mobile */
      display: none;
    }
  }
  
  /* Style for not required items */
  
  .not-required .score-value,
  .not-required .score-number {
    color: #888 !important;
  }
  
  .not-required .score-indicator {
    border: 1px solid #ccc !important;
    background-color: transparent !important;
  }
  
  .not-required .score-tooltip .tooltip-value {
    color: #f0f0f0 !important;
  }
  
  .info-icon-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
  }
  
  .info-icon-wrapper .info-tooltip {
    position: absolute;
    visibility: hidden;
    background-color: #333;
    color: white;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 0.85rem;
    width: max-content;
    min-width: 200px;
    max-width: 400px;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    line-height: 1.4;
  }
  
  .info-icon-wrapper:hover .info-tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  /* Add a right-pointing arrow to the info tooltip */
  .info-tooltip::before {
    content: '';
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent #333 transparent transparent;
  }