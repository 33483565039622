.pb-container {
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.pb-header {
    margin-bottom: 16px;
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 14px;
}

.pb-header h4 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0 0 12px 0;
}

.pb-total-lines {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #555;
}

.pb-value {
    font-weight: 600;
    color: #32AFC3;
}

.pb-breakdown-items {
    margin-bottom: 20px;
}

.pb-item {
    padding: 12px 0;
    border-bottom: 1px dashed #e5e7eb;
}

.pb-item:last-child {
    border-bottom: none;
}

.pb-item-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 6px;
}

.pb-tier-name-container {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    max-width: 75%;
}

.pb-tier-name {
    font-weight: 600;
    font-size: 15px;
    color: #444;
    margin-right: 6px;
}

.pb-tier-descriptor {
    font-weight: 400;
    font-size: 14px;
    color: #666;
}

.pb-tier-amount {
    font-weight: 600;
    font-size: 15px;
    color: #333;
    text-align: right;
    min-width: 80px;
}

.pb-item-details {
    font-size: 14px;
    color: #666;
    padding-left: 4px;
}

.pb-item-description {
    font-size: 13px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
}

.pb-summary {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #e5e7eb;
}

.pb-summary-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 14px;
    color: #555;
}

.pb-subtotal {
    font-weight: 500;
}

.pb-tax {
    color: #666;
}

.pb-total {
    font-weight: 700;
    font-size: 16px;
    color: #333;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e5e7eb;
}

.pb-disclaimer {
    margin-top: 15px;
    font-size: 12px;
    color: #777;
    font-style: italic;
}

.pb-tier-progress {
    margin-top: 15px;
}

.pb-tier-label {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #555;
    margin-bottom: 5px;
}

.pb-progress-bar {
    height: 8px;
    background-color: #e9ecef;
    border-radius: 4px;
    overflow: hidden;
}

.pb-progress-fill {
    height: 100%;
    border-radius: 4px;
    transition: width 0.6s ease;
}

.pb-tier-range {
    font-weight: 500;
    color: #444;
    margin-right: 4px;
}

.pb-tier-used {
    color: #666;
    font-size: 13px;
    font-style: italic;
} 