/* DependenciesGraph.css */
.dependencies-graph-container {
  width: 100%;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: hidden;
  min-height: 700px;
  display: flex;
  height: 100%;
  background-color: #fff;
}

.dependencies-graph-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  margin: 0 auto;
}

.graph-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 650px;
}

.graph-visualization {
  position: relative;
  flex: 1;
  min-height: 500px;
  overflow: hidden;
}

/* Refresh button styling */
.refresh-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, background-color 0.2s;
  z-index: 10;
}

.refresh-button:hover {
  background-color: #32AFC3;
  transform: rotate(15deg);
}

.refresh-button .pi {
  font-size: 16px;
  color: #fff;
}

/* New sidebar container that holds both legend and details */
.graph-sidebar {
  width: 300px;
  padding: 15px;
  background-color: #f8f9fa;
  border-left: 1px solid #e9ecef;
  overflow-y: auto;
  height: 700px;
  display: flex;
  flex-direction: column;
}

.dependencies-graph {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

/* Node Styles */
.node {
  cursor: pointer;
  transition: fill-opacity 0.2s, stroke-width 0.2s;
}

/* Selected Node Style */
.node.selected {
  stroke-width: 4px !important;
  fill-opacity: 0.9 !important;
  filter: drop-shadow(0 0 8px rgba(0,0,0,0.5)) !important;
}

/* Link Styles */
.link {
  stroke: #999;
  stroke-opacity: 0.3;
  transition: stroke-opacity 0.2s, stroke-width 0.2s;
}

.link.highlighted {
  stroke-opacity: 0.8;
  stroke-width: 2px;
}

/* Label Styles */
.graph-label {
  font-size: 12px;
  pointer-events: none;
  font-family: sans-serif;
  fill: #333;
}

.text-background {
  transition: opacity 0.2s ease;
}

/* Legend Styles */
.graph-legend {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e9ecef;
}

.legend-section {
  margin-bottom: 15px;
}

.legend-section h4 {
  font-size: 14px;
  margin-bottom: 8px;
  color: #495057;
  font-weight: 600;
}

.legend-items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 4px;
}

.legend-color {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 6px;
  display: inline-block;
}

.legend-label {
  font-size: 12px;
  color: #495057;
}

/* Make package status indicators circular */
.status-active,
.status-deprecated,
.status-maintained,
.status-unmaintained,
.status-unknown,
.status-built-in {
  border-radius: 50%;
}

/* Status colors */
.status-active {
  background-color: #4caf50;
}

.status-deprecated {
  background-color: #f44336;
}

.status-maintained {
  background-color: #2196f3;
}

.status-unmaintained {
  background-color: #ff9800;
}

.status-unknown {
  background-color: #9e9e9e;
}

.status-built-in {
  background-color: #9c27b0;
}

/* Details Container */
.details-container {
  padding: 15px;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  background-color: white;
  margin-top: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  min-height: 150px;
}

.details-container h3 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e9ecef;
  color: #343a40;
  font-weight: 600;
}

.details-name {
  font-weight: 600;
}

/* Package name styling */
.details-name:not(.file-selected):not(.file-not-selected) {
  color: #32AFC3;
}

/* Selected file styling */
.file-selected {
  color: #32AFC3;
}

/* Non-selected file styling */
.file-not-selected {
  color: #6c757d;
}

.file-link {
  color: #32AFC3;
  text-decoration: none;
  transition: color 0.2s;
}

.file-link:hover {
  color: #2590a1;
  text-decoration: underline;
}

.details-content {
  font-size: 14px;
}

.details-row {
  display: flex;
  margin-bottom: 12px;
}

.details-label {
  width: 110px;
  font-size: 12px;
  font-weight: 500;
  color: #6c757d;
}

.details-value {
  flex: 1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #343a40;
}

.details-value.status-active,
.details-value.status-deprecated,
.details-value.status-maintained,
.details-value.status-unmaintained,
.details-value.status-built-in,
.details-value.status-unknown {
  padding: 2px 6px;
  border-radius: 3px;
  color: white;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  max-width: fit-content;
}

.details-value.status-built-in {
  background-color: #9c27b0;
}

/* Details placeholder when no node is selected */
.details-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;
  text-align: center;
  color: #6c757d;
}

.details-placeholder-icon {
  margin-bottom: 15px;
  font-size: 24px;
  color: #adb5bd;
}

.details-placeholder-text {
  font-size: 14px;
  max-width: 220px;
  line-height: 1.5;
}

/* Container to ensure visibility */
.container {
  width: 100%;
  height: 100%;
}

/* Ensure links and nodes are visible */
.links, .nodes {
  visibility: visible;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .graph-content {
    flex-direction: column;
  }
  
  .graph-visualization {
    min-height: 500px;
  }
  
  .graph-sidebar {
    width: 100%;
    height: auto;
    min-width: 100%;
    border-left: none;
    border-top: 1px solid #eee;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .graph-legend {
    width: 50%;
    max-height: none;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    border-right: 1px solid #eee;
  }
  
  .details-container {
    width: 50%;
    padding-left: 15px;
  }
  
  .legend-section {
    flex: 1;
    min-width: 150px;
  }
  
  .refresh-button {
    top: 5px;
    right: 5px;
    width: 36px !important;
    height: 36px !important;
  }
}

@media (max-width: 768px) {
  .graph-sidebar {
    flex-direction: column;
  }
  
  .graph-legend, .details-container {
    width: 100%;
    border-right: none;
  }
  
  .graph-legend {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.dependencies-graph-loading,
.dependencies-graph-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
  gap: 1rem;
}

.dependencies-graph-loading i {
  color: #777;
}

/* Add to existing CSS file */
.dependencies-graph-panel .p-panel-content {
  min-height: 400px;
  padding: 0;
  overflow: hidden;
}

/* Add cursor: pointer to all nodes to indicate clickability */
.node-group {
  cursor: pointer;
}
