.time-fix-graph-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.time-fix-graph {
  overflow: visible;
}

.time-fix-bar {
  transition: opacity 0.2s ease, stroke 0.2s ease;
}

.bar-value {
  pointer-events: none;
}

.fix-time-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
}
