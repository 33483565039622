/* Main Layout */
.hero-main-content {
  background-image: linear-gradient(100deg, #ffffff 0%, rgba(149, 219, 231, 0.4) 30%, rgba(243, 143, 252, 0.6) 100%);
  background-size: cover;
  position: relative;
}

/* Common Button Styles */
.hero-cta-button,
.intro-cta-button,
.cta-primary-button {
  border: 2px solid #32AFC3;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.cta-gradient-title {
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: 48px;
  text-align: center;
  background: linear-gradient(90deg, #F8A1D1 0%, #9F5FE9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-cta-button,
.cta-primary-button {
  background-color: #32AFC3;
  color: white;
  border: 2px solid #32AFC3;
}

.hero-cta-button:hover {
  background-color: white;
  color: #32AFC3;
  border: 2px solid #32AFC3;
}

.cta-primary-button {
  width: 250px;
  padding: 14px 28px;
  font-size: 1.1rem;
  border-radius: 6px;
}

.intro-cta-button {
  background-color: #323232;
  color: white;
  border-color: #323232;
}

.intro-cta-button:hover {
  background-color: #4a4a4a;
  border: 2px solid #4a4a4a;
}

.hero-secondary-button {
  background-color: transparent;
  color: #32AFC3;
  border: 2px solid #32AFC3;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.hero-secondary-button:hover {
  background-color: #32AFC3;
  color: white;
}

/* Hero Section */
.hero-main-content .hero-section {
  position: relative;
  overflow: hidden;
  padding: 4rem 0 4rem 0;
  display: flex;
  flex-direction: column;
}

.hero-main-content .hero-content {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0 5%;
  flex: 1;
}

.hero-main-content .hero-content::-webkit-scrollbar {
  display: none;
}

.hero-main-content .hero-title-container {
  flex: 0 0 45%;
  max-width: 800px;
  min-width: 350px;
  padding-right: 20px;
  margin-right: 5%;
}

.hero-main-content .hero-cards-container {
  flex: 1;
  display: flex;
  gap: 25px;
  overflow-x: visible;
  padding: 10px;
}

.hero-main-content .hero-title {
  font-size: 2.5em;
  color: #323232;
  margin-bottom: 20px;
}

.hero-main-content .hero-description {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 30px;
  max-width: 600px;
}

.hero-main-content .hero-buttons {
  display: flex;
  gap: 16px;
  align-items: center;
}

/* Hero Cards */
.hero-main-content .hero-card {
  flex: 0 0 auto;
  width: 405px;
  min-width: 330px;
  min-height: 405px;
  background-color: white;
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin: 10px;
}

.hero-main-content .hero-card:hover {
  transform: scale(1.02);
}

.hero-main-content .hero-card-image {
  width: 100%;
  height: 305px;
  border-radius: 8px;
  margin-bottom: 14px;
  object-fit: cover;
  object-position: center;
}

.hero-main-content .hero-card-link {
  display: flex;
  align-items: center;
  color: #323232;
  transition: color 0.3s ease;
}

.hero-main-content .hero-card:hover .hero-card-link {
  color: #32AFC3;
}

.hero-main-content .hero-card-link span {
  margin-right: 8px;
}

.hero-main-content .hero-card-link .pi-angle-right {
  transition: transform 0.3s ease;
}

.hero-main-content .hero-card:hover .hero-card-link .pi-angle-right {
  transform: translateX(5px);
}

/* Slider */
.hero-main-content .slider-container {
  display: flex;
  align-items: center;
  width: 40%;
  margin-left: 50%;
}

.hero-main-content .slider-bar {
  flex-grow: 1;
  height: 2px;
  background-color: #fff;
  position: relative;
  margin: 0 10px;
}

.hero-main-content .slider-progress {
  position: absolute;
  height: 100%;
  background-color: #323232;
  transition: width 0.3s ease;
}

.hero-main-content .slider-arrow {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #323232;
}

.hero-main-content .slider-arrow:hover {
  border: none;
  background: none;
}

/* Intro Block */
.intro-block {
  position: relative;
  padding: 50px 0;
}

.intro-block::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,1) 40%,
    rgba(255,255,255,1) 100%
  );
  pointer-events: none;
  z-index: 1;
}

.intro-section {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15%;
  padding-bottom: 2rem;
}

.intro-section.intro-section-2 {
  position: relative;
  overflow: hidden;
  --parallax-offset: 0px;
  min-height: 550px;
  background-color: transparent;
}

.intro-section.intro-section-2::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1800px;
  height: 900px;
  background-image: url('./images/frontpage/sec_background.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(-50%, calc(-50% + var(--parallax-offset)));
  z-index: 0;
  will-change: transform;
  pointer-events: none;
  transition: transform 0.1s ease-out;
}

.intro-section.intro-section-2 .intro-text-column,
.intro-section.intro-section-2 .intro-image-column {
  position: relative;
  z-index: 1;
}

.intro-text-column,
.intro-image-column {
  flex: 0 0 48%;
}

.intro-text-column h2 {
  font-size: 36px;
  color: #323232;
  margin-bottom: 20px;
}

.intro-text-column p {
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
}

.intro-image-column img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Feature Section */
.feature-section {
  padding: 80px 0;
  background: #1D2B40;
  width: 100%;
  position: relative;
  z-index: 2;
}

.feature-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  align-items: center;
}

.feature-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.feature-text h1 {
  font-size: 2.5rem;
  color: white;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

.feature-text h1 .gradient-text {
  font-size: inherit;
  background: linear-gradient(90deg, #F8A1D1 0%, #9F5FE9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.feature-buttons {
  display: flex;
  gap: 12px;
}

.feature-button {
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background: white;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  text-transform: lowercase;
  border: 2px solid #1D2B40;
}

.feature-button.active {
  background: #32AFC3;
  color: white;
  border: 2px solid #32AFC3;
}

.feature-button.active:hover {
  background: #2b95a6;
  border: 2px solid #2b95a6;
}

.feature-button:hover {
  background: #f5f5f5;
  border: 2px solid #1D2B40;
}

.feature-description {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.feature-description h3 {
  font-size: 1.2rem;
  color: white;
  margin: 0;
  font-weight: 400;
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.feature-list-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.feature-list-item i {
  font-size: 1.2rem;
  color: #32AFC3;
}

.feature-list-item h2 {
  font-size: 1.4rem;
  color: white;
  margin: 0;
  font-weight: 500;
}

.feature-image-container {
  position: relative;
  width: 100%;
}

.feature-image {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.feature-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.feature-cta-button {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: #32AFC3;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.feature-cta-button:hover {
  background: #2b95a6;
  transform: translateX(-50%) translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

/* Combined Audits and CTA Section */
.audits-with-cta {
  position: relative;
  min-height: 1150px;
  background-image: url('./images/frontpage/gradient.png');
  background-size: cover;
  background-position: top center;
  display: flex;
  flex-direction: column;
}

.audits-block {
  flex: 1;
  padding: 80px 0 300px;
  width: 100%;
  position: relative;
  z-index: 2;
  overflow-x: hidden;
}

.audits-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 48px;
  align-items: start;
}

.audits-text {
  padding-right: 48px;
}

.audits-text h2 {
  font-size: 2.5rem;
  color: #323232;
  margin: 0 0 24px 0;
  font-weight: 600;
  line-height: 1.2;
}

.audits-text p {
  font-size: 1.2rem;
  color: #666;
  margin: 0;
  line-height: 1.5;
}

.audits-examples-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.audits-examples h2 {
  font-size: 1.4rem;
  color: white;
  margin: 0 0 5px 0;
  font-weight: 600;
}

.audit-cards-container {
  display: flex;
  flex-direction: column;
}

/* Audit Cards */
.audit-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  min-width: 450px;
  margin-bottom: 24px;
  background: white;
  border-radius: 12px;
  padding: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.audit-card-wrapper {
  opacity: 0;
  transform: translateX(100%);
  transition: all 1.2s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  width: 100%;
}

.audit-card-wrapper.visible {
  opacity: 1;
  transform: translateX(0);
}

.audit-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.audit-card-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

.audit-logo {
  height: 32px;
  width: auto;
  object-fit: contain;
  align-self: flex-start;
}

.audit-repo-link {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.audit-repo-link:hover {
  color: #32AFC3;
}

.audit-stats {
  color: #666;
  font-size: 0.9rem;
}

/* Explore More Link */
.explore-more {
  margin-top: 12px;
  font-size: 1rem;
  color: #666;
  padding-left: 24px;
}

.explore-more a {
  color: #32AFC3;
  text-decoration: none;
  margin-left: 4px;
  transition: color 0.3s ease;
}

.explore-more a:hover {
  color: #2b95a6;
  text-decoration: underline;
}

/* Call to Action Section */
.hero-main-content .cta-container {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1D2B40;
  min-height: 250px;
  z-index: 4;
  margin-top: -150px;
  padding-bottom: 100px;
  visibility: visible;
  transform: none;
}

.hero-main-content .cta-content {
  position: relative;
  width: 70%;
  max-width: 1200px;
  margin: -100px auto 0;
  padding: 48px;
  background: #1D2B40;
  border-radius: 12px;
  border: 2px solid white;
  color: white;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  z-index: 5;
  text-align: center;
  visibility: visible;
  transform: none;
}

.cta-title {
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: 48px;
  text-align: center;
}

.gradient-text {
  background: linear-gradient(90deg, #F8A1D1 0%, #9F5FE9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.cta-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10%;
  align-items: start;
}

.cta-left,
.cta-right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;
}

.cta-left p,
.cta-right p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  line-height: 1.5;
  text-align: center;
}

.cta-primary-button {
  margin: 0 auto;
}

.pricing-link {
  font-size: 1.8rem;
  color: white;
  margin: 0;
  cursor: pointer;
  transition: color 0.3s ease;
  position: relative;
  display: inline-block;
  text-align: center;
}

.pricing-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #32AFC3;
  transition: width 0.3s ease;
  transform-origin: left;
}

.pricing-link:hover {
  color: #32AFC3;
}

.pricing-link:hover::after {
  width: 100%;
}

/* Consolidated Media Queries */
@media (max-width: 1400px) {
  .cta-content {
    width: 80%;
  }
}

@media (max-width: 1200px) {
  /* Typography */
  .hero-title,
  .feature-text h1,
  .audits-text h2,
  .cta-content h1 {
    font-size: 2.2rem;
  }

  .audits-examples h2,
  .pricing-link {
    font-size: 1.6rem;
  }

  .cta-content {
    width: 85%;
  }

  .intro-section {
    padding: 0 2rem 6rem;
  }

}

@media (max-width: 768px) {
  /* Layout adjustments */
  .hero-section {
    height: auto;
    min-height: 0;
  }

  .hero-content {
    flex-direction: column;
    height: auto;
    overflow-y: visible;
  }

  .hero-title-container,
  .hero-cards-container {
    flex: 1 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
    margin-top: 24px;
    min-width: 360px;
  }

  .slider-container {
    position: relative;
    width: 90%;
    right: auto;
    margin: 20px auto;
  }

  /* Grid Layouts */
  .intro-section,
  .feature-content,
  .audits-content,
  .cta-grid {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  /* Spacing and Alignment */
  .hero-title-container,
  .hero-cards-container,
  .intro-text-column,
  .intro-image-column,
  .audits-text {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .intro-image-column {
    order: 2;
  }

  /* Typography */
  .hero-title,
  .feature-text h1,
  .audits-text h2,
  .cta-content h1 {
    font-size: 2rem;
  }

  .feature-list h2,
  .pricing-link {
    font-size: 1.4rem;
  }

  .feature-description h3,
  .cta-left p,
  .cta-right p {
    font-size: 1.1rem;
  }

  /* Button and Interactive Elements */
  .hero-buttons {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .hero-cta-button,
  .hero-secondary-button,
  .cta-primary-button {
    width: 100%;
    text-align: center;
  }

  /* Audit Cards */
  .audit-card-wrapper {
    transform: translateX(50%);
  }

  .audits-text {
    padding-right: 90px;
  }

  /* CTA Section */
  .hero-main-content .cta-content {
    width: 90% !important;
    padding: 32px;
    margin-top: -80px;
  }

  .hero-main-content .cta-container {
    margin-top: -80px;
    padding: 40px 20px;
  }

  .cta-content h1 {
    margin-bottom: 32px;
  }

  .audits-examples h2 {
    color: #323232;
  }

  .intro-section {
    flex-direction: column;
    padding: 0 2rem 6rem;
  }

  .intro-section.intro-section-2 {
    min-height: auto;
    padding: 4rem 2rem;
  }

  .intro-text-column,
  .intro-image-column {
    flex: 0 0 100%;
    width: 100%;
  }

  .intro-image-column {
    display: none;
  }

  .intro-text-column {
    padding-right: 0;
    margin-bottom: 0;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 32px;
  }
}

@media (max-width: 580px) {
  /* Layout */
  .intro-image-column {
    display: none;
  }

  .hero-main-content .hero-cards-container{
    display: none;
  }

  .hero-main-content .slider-container{
    display: none;
  }

  .feature-content,
  .audits-content,
  .cta-content {
    padding: 0 16px;
  }

  /* Section Spacing */
  .audits-block,
  .cta-container {
    padding: 48px 0;
  }

  /* CTA Specific */
  .cta-container {
    margin-top: -60px;
    padding: 20px;
  }

  .cta-content {
    width: calc(100% - 32px);
    padding: 24px;
    bottom: 5%;
  }

  /* Typography */
  .audits-text h2,
  .audits-examples h2,
  .cta-content h1 {
    font-size: 1.8rem;
  }

  .audits-text p {
    font-size: 1rem;
  }

  .pricing-link {
    font-size: 1.4rem;
  }

  /* Buttons */
  .feature-buttons {
    flex-wrap: wrap;
  }

  .feature-button {
    flex: 1 1 auto;
    text-align: center;
  }

  .audits-with-cta {
    height: 1750px;
  }
  
}


/* Benefits Section */
.benefits-section {
  position: relative;
  z-index: 2;
  padding: 0 5rem;
  padding-bottom: 2rem;
  padding-top: 4rem;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.benefit-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  border: 1px solid lightgray;
  border-radius: 8px;
  background-color: white;
}

.benefit-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
  object-fit: contain;
}

.benefit-card h3 {
  font-size: 1.25rem;
  color: #323232;
  margin: 0 0 1rem 0;
  font-weight: 600;
  line-height: 1.4;
}

.benefit-card p {
  font-size: 1rem;
  color: #666;
  margin: 0;
  line-height: 1.6;
}




@media (max-width: 1200px) {
  .benefits-section {
    padding: 0 2rem;
  }
  
  .benefits-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem 2rem;
  }
}

@media (max-width: 768px) {
  .benefits-section {
    padding: 0 1.5rem;
    margin: 1rem 0 4rem;
  }

  .benefits-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .benefit-card {
    padding: 1rem;
  }

  .benefit-card h3 {
    font-size: 1.2rem;
  }
}