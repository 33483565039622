/* Error page specific styles */

.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--surface-ground);
}

.error-content {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
}

.content-error-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-card {
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.error-illustration {
    margin: 20px 0;
    max-width: 200px;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.start-heading {
    font-size: 24px;
    margin-bottom: 10px;
}

.start-subheading {
    font-size: 18px;
    margin-bottom: 20px;
}

.error-message {
    color: #666;
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.error-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex: 1;
    min-width: 120px;
    max-width: 200px;
}

.error-button:not(.primary) {
    background-color: #f0f0f0;
    color: #333;
}

.error-button:not(.primary):hover {
    background-color: #e0e0e0;
}

.error-button.primary {
    background-color: #32AFC3;
    color: white;
}

.error-button.primary:hover {
    background-color: #2B95A7;
    outline: none;
    box-shadow: none;
}

.error-button:focus {
    outline: none;
}

/* Responsive styles */
@media (max-width: 480px) {
    .start-heading {
        font-size: 20px;
    }

    .start-subheading {
        font-size: 16px;
    }

    .error-button {
        width: 100%;
        max-width: none;
    }
}

@media (max-width: 320px) {
    .start-heading {
        font-size: 18px;
    }

    .start-subheading {
        font-size: 14px;
    }

    .error-message {
        font-size: 14px;
    }
}