/* Custom TabMenu Styles */

/* General styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: large;
    line-height: 1.6;
  }
  
  .p-tabmenu {
    display: flex;
    width: 100%;
  }
  
  .p-tabmenu .p-tabmenu-nav {
    background-color: transparent;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    background-color: transparent;
    color: #8D8D8D;
    flex-grow: 1;
    text-align: center;
    position: relative;
  }
  
  /* Active Tab Styles */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight {
    color: #323232;
    font-weight: bold;
  }
  
  /* Underline for Active Tab */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #32AFC3;
    margin-top: 15px;
  }
  
  /* Non-active Tab Styles */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #8D8D8D;
    margin-top: 15px;
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .tabmenu-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8D8D8D;
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .tabmenu-item-link i {
    margin-right: 5px;
    color: #32AFC3;
  }
  
  /* Active Tab Styles */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .tabmenu-item-link {
    color: #32AFC3;
  }
  
  /* Active Tab Icon Styles */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .tabmenu-item-link i {
    color: #32AFC3;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .tab-menu-container {
      margin-left: 0;
    }
  
    .p-button.p-button-icon-only {
      width: 2.357rem;
      padding: 0.5rem 0;
    }
  
    .p-menu {
      width: 100%;
      max-width: 20rem;
    }
  
    .p-menuitem-link {
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      color: #495057;
      transition: background-color 0.2s, box-shadow 0.2s;
    }
  
    .p-menuitem-icon {
      margin-right: 0.5rem;
    }
  
    .p-menuitem-text {
      line-height: 1;
    }
  
    .p-menuitem-link:hover {
      background-color: #e9ecef;
    }
  
    .p-menuitem-link.active-tab {
      background-color: #f8f9fa;
    }
  
    .p-menuitem-link.active-tab .p-menuitem-icon,
    .p-menuitem-link.active-tab .p-menuitem-text {
      color: #32AFC3;
    }
  
    .p-menuitem-link.active-tab .p-menuitem-text {
      padding-left: 45px;
    }
  }

/* Admin TabMenu Styles */

.admin-tab-menu-container {
  width: 100%;
  background-color: var(--surface-card);
  border-bottom: 1px solid var(--surface-border);
  margin-bottom: 1.5rem;
}

/* Desktop TabMenu Styles */
.admin-custom-tabmenu {
  padding: 0 1rem;
}

.admin-custom-tabmenu .p-tabmenu-nav {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.admin-custom-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0.5rem;
  background-color: transparent;
  border: none;
  position: relative;
}

.admin-custom-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  padding: 1rem 1.25rem;
  color: var(--text-color-secondary);
  border: none;
  border-radius: 0;
  transition: background-color 0.2s, color 0.2s;
}

.admin-custom-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}

.admin-custom-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
  color: var(--text-color-secondary);
}

.admin-custom-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
  color: var(--text-color-secondary);
  font-weight: 500;
}

/* Active Tab Styles */
.admin-custom-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background-color: transparent;
  border-color: transparent;
  color: var(--primary-color);
  position: relative;
}

.admin-custom-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--primary-color);
}

.admin-custom-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-icon,
.admin-custom-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-text {
  color: var(--primary-color);
}

.admin-custom-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:hover {
  background-color: var(--surface-hover);
  border-color: transparent;
}

/* Mobile TabMenu Styles */
.admin-mobile-tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: var(--surface-card);
  border-bottom: 1px solid var(--surface-border);
}

.admin-current-tab {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: var(--primary-color);
}

.admin-current-tab i {
  margin-right: 0.5rem;
}

/* Mobile Menu Styles */
.p-menu.p-menu-overlay {
  border: 1px solid var(--surface-border);
  border-radius: 6px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: var(--text-color);
  transition: background-color 0.2s;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  color: var(--text-color-secondary);
  margin-right: 0.5rem;
}

.p-menu .p-menuitem-link:hover {
  background-color: var(--surface-hover);
}

.p-menu .p-menuitem-link:focus {
  box-shadow: none;
  outline: 0 none;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .admin-tab-menu-container {
    margin-bottom: 1rem;
  }
  
  .admin-mobile-tab-header {
    padding: 0.75rem;
  }
}