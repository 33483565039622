/* FlagsGraph.css */
.flags-graph-container {
    width: 100%;
    padding: 1rem;
}

.flags-graph-title {
    font-size: 1rem;
    font-weight: 500;
    color: #4B5563;
    margin-bottom: 1rem;
}

.flags-graph-disclaimer {
    font-size: 0.8rem;
    color: #6B7280;
    font-style: italic;
    margin-top: 0.5rem;
    padding: 0.5rem;
    background-color: #F9FAFB;
    border-radius: 4px;
    border-left: 3px solid #E5E7EB;
}

.flags-graph-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    min-width: 336px;
}

.flags-graph-legend {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
}

.flags-graph-legend-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #6B7280;
}

.flags-graph-legend-color {
    width: 12px;
    height: 12px;
    border-radius: 3px;
}

/* Interactive elements */
.arc { 
    cursor: pointer;
    transition: opacity 0.3s ease, filter 0.3s ease;
    /* Remove stroke-width which can cause layout shifts */
    /* stroke-width is now handled in JS with filter effects */
    box-sizing: border-box;
}

.arc:hover { 
    opacity: 0.85;
    filter: brightness(1.05);
    /* Remove stroke properties in favor of filter effects */
}

.arc text {
    font-size: 0.85rem;
    font-weight: 500;
    pointer-events: none;
}

.arc path {
    transition: all 0.3s ease;
}

/* Tooltip styles */
.flags-graph-tooltip {
    max-width: 300px;
}

.flags-graph-tooltip .p-tooltip-text {
    white-space: pre-line;
    font-size: 13px;
    line-height: 1.5;
    padding: 12px;
    background: white;
    color: #323232;
    border: 1px solid #e5e7eb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

/* Additional details panel */
.flags-details-panel {
    margin-top: 1rem;
    padding: 1rem;
    border-top: 1px solid #E5E7EB;
    font-size: 0.875rem;
    color: #4B5563;
    width: 100%;
    min-height: 180px; /* Fixed height to prevent layout shifts */
    max-width: 400px;
}

.flags-details-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.flags-details-label {
    font-weight: 500;
    color: #6B7280;
}

.flags-details-value {
    color: #111827;
}

.flags-priority-high {
    color: #E83636;
}

.flags-priority-medium {
    color: #DE7539;
}

.flags-priority-low {
    color: #2CB392;
}

.flags-details-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #9CA3AF;
    background-color: #F9FAFB;
    border-radius: 6px;
    padding: 1.5rem 1rem;
}

.flags-details-placeholder-icon {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    color: #6B7280;
}

.flags-details-placeholder-text {
    font-size: 0.875rem;
    line-height: 1.5;
    max-width: 300px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .flags-graph-content {
        flex-direction: column;
    }
}

/* Make sure the SVG maintains its size */
.flags-graph-content svg {
    box-sizing: border-box;
    overflow: visible;
    transform-origin: center center;
    position: relative;
    shape-rendering: geometricPrecision;
    /* Fix the dimensions to prevent any resizing */
    min-width: 336px; 
    min-height: 336px;
}

/* Sunburst container to maintain consistent dimensions */
.flags-graph-sunburst-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

