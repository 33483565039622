/* Blog List Page Styles */
.blog-page {
  position: relative;
  min-height: 100vh;
  background-color: #1C2B40;
}

/* Hero Section */
.blog-hero {
  padding: 80px 0 40px 0;
  width: 100%;
  position: relative;
  z-index: 2;
}

.blog-hero-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.blog-hero-text {
  max-width: 800px;
  margin-bottom: 48px;
}

.blog-hero-text h3 {
  font-size: 1.2rem;
  color: white;
  margin: 0;
  font-weight: 400;
}

/* Hero Title Styles */
.hero-title {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 16px 0;
}

.gradient-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  white-space: nowrap;
}

.hero-gradient-text {
  font-size: 3.5rem;
  font-weight: 600;
  background: linear-gradient(
    45deg,
    #F8A1D1 0%,
    #9F5FE9 25%,
    #F8A1D1 50%,
    #9F5FE9 75%,
    #F8A1D1 100%
  );
  background-size: 400% 400%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hero-gradient-flow 15s ease infinite;
}

.hero-title h1 {
  font-size: 3.5rem;
  color: white;
  margin: 0;
  font-weight: 600;
}

.hero-description-blog {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 24px 0 0 0;
  line-height: 1.6;
}

/* Blog Section */
.blog-section {
  padding: 40px 0 80px 0;
}

.blog-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 32px;
  margin-top: 48px;
}

/* Blog Card */
.blog-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.blog-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.blog-card-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.blog-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-card-content {
  padding: 24px;
}

.blog-card-title {
  color: white;
  font-size: 1.5rem;
  margin: 0 0 12px 0;
  line-height: 1.4;
}

.blog-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  margin: 0 0 24px 0;
  line-height: 1.6;
}

.blog-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.blog-card-date {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
}

.blog-card-read-more {
  color: #32AFC3;
  font-weight: 500;
  transition: color 0.2s ease;
}

.blog-card:hover .blog-card-read-more {
  color: #F8A1D1;
}

/* Blog Detail Page Styles */
.blog-detail-page {
  background-color: #1C2B40;
  min-height: 100vh;
  color: rgba(255, 255, 255, 0.9);
}

.blog-detail-hero {
  background: rgba(255, 255, 255, 0.02);
  padding: 80px 0 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.blog-detail-header {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 24px;
  text-align: center;
}

.back-to-blog {
  background: none;
  border: none;
  color: #32AFC3;
  font-size: 1rem;
  cursor: pointer;
  padding: 8px 16px;
  margin-bottom: 32px;
  transition: color 0.2s ease;
}

.back-to-blog:hover {
  color: #F8A1D1;
}

.blog-detail-title {
  font-size: 3rem;
  color: white;
  margin: 0 0 16px;
  line-height: 1.2;
}

.blog-detail-subtitle {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 24px;
  font-weight: 400;
}

.blog-detail-meta {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
}

.blog-detail-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 48px 24px;
}

/* Markdown Content Styles */
.markdown-content {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.8;
}

.markdown-content * {
  color: rgba(255, 255, 255, 0.9);
}

.md-h1 {
  font-size: 2.5rem;
  color: white;
  font-weight: 600;
}

.md-h2 {
  font-size: 2rem;
  color: white;
  font-weight: 600;
}

.md-h3 {
  font-size: 1.5rem;
  color: white;
  font-weight: 500;
}

.md-p {
  margin: 0.75em 0;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
}

.md-link {
  color: #32AFC3;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease;
}

.md-link:hover {
  border-bottom-color: #32AFC3;
  color: #32AFC3;
}

.md-image-container {
  margin: 1.5em 0;
}

.md-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.md-ul, .md-ol {
  margin: 0.75em 0;
  padding-left: 2em;
  color: rgba(255, 255, 255, 0.9);
}

.md-li {
  margin: 0.5em 0;
  color: rgba(255, 255, 255, 0.9);
}

.md-blockquote {
  margin: 1.5em 0;
  padding: 1em 2em;
  border-left: 4px solid #32AFC3;
  background: rgba(255, 255, 255, 0.05);
  font-style: italic;
  color: rgba(255, 255, 255, 0.9);
}

.md-inline-code {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-family: monospace;
  color: rgba(255, 255, 255, 0.9);
}

.md-code-block {
  background: rgba(255, 255, 255, 0.1);
  padding: 1em;
  border-radius: 8px;
  margin: 1.5em 0;
  overflow-x: auto;
}

.md-code-block code {
  font-family: monospace;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

/* Add styles for horizontal rules */
.markdown-content hr {
  margin: 2em 0;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Loading State */
.blog-detail-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1C2B40;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #32AFC3;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes hero-gradient-flow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .blog-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .hero-gradient-text,
  .hero-title h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .blog-hero {
    padding: 60px 0;
  }

  .hero-title {
    flex-direction: column;
    gap: 8px;
  }

  .hero-gradient-text,
  .hero-title h1,
  .blog-detail-title {
    font-size: 2.5rem;
  }

  .blog-grid {
    grid-template-columns: 1fr;
  }

  .blog-detail-subtitle {
    font-size: 1.2rem;
  }

  .md-h1 {
    font-size: 2rem;
  }

  .md-h2 {
    font-size: 1.75rem;
  }

  .md-h3 {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .blog-hero {
    padding: 40px 0;
  }

  .hero-gradient-text,
  .hero-title h1,
  .blog-detail-title {
    font-size: 2rem;
  }

  .blog-content,
  .blog-detail-content {
    padding: 0 16px;
  }

  .md-blockquote {
    padding: 1em;
  }
} 